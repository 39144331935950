import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import CreateableSelectWithDelete from "../../components/CreateableSelectWithDelete";
import { format, parseISO } from "date-fns"; // Import date-fns for date formatting
import ENDPOINTS from "../../service/endpoint";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import axiosInstance from "../../service/axiosInstance";

const EditOrder = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = Cookies.get("accessToken");

  const navigate = useNavigate();
  const { orderId } = useParams();

  const [factoryProductName, setFactory] = useState("");
  const [promisedStartDate, setStart] = useState("");
  const [deadline, setDeadline] = useState("");
  const [totalQuantity, setTotalQuantity] = useState("");
  const [shippedQuantity, setShippedQuantity] = useState(0);
  const [status, setStatus] = useState("Not Started");

  const [variants, setVariants] = useState([]);

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [factoryProducts, setFactoryProducts] = useState([]);
  const [selectedFactoryProduct, setSelectedFactoryProduct] = useState(null);

  const [customerProducts, setCustomerProducts] = useState([]);
  const [selectedCustomerProduct, setSelectedCustomerProduct] = useState(null);

  useEffect(() => {
    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split("-");
      return `${year}-${month}-${day}`;
    };

    const fetchOrder = async () => {
      try {
        const response = await axiosInstance.get(
          `${ENDPOINTS.ORDER_LIST}${orderId}`
        );
        const fetchedOrder = response?.data?.data;
        console.log("🚀 ~ fetchOrder ~ fetchedOrder:", fetchedOrder);

        setSelectedFactoryProduct({
          value: fetchedOrder.factoryProductName,
          label: fetchedOrder.factoryProductName,
        });
        setSelectedCustomer({
          value: fetchedOrder.customerId,
          label: fetchedOrder.customerName,
        });
        setSelectedCustomerProduct({
          value: fetchedOrder.customerProductName,
          label: fetchedOrder.customerProductName,
        });
        // setVariants({
        //   value: fetchedOrder.productVariant,
        //   label: fetchedOrder.productVariant
        // })
        setVariants(response?.data?.data?.productVariant);
        setStart(parseDate(fetchedOrder.promisedStartDate));
        setDeadline(parseDate(fetchedOrder.deadline));
        setTotalQuantity(fetchedOrder.totalQuantity);
        setShippedQuantity(fetchedOrder.shippedQuantity);
        setStatus(fetchedOrder.status);
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    };

    const fetchProductsAndCustomers = async () => {
      try {
        const [productResponse, customerResponse, customerProductNameResponse] =
          await Promise.all([
            axiosInstance.get(`${baseURL}${ENDPOINTS.PRODUCT_LIST}`),
            axiosInstance.get(`${baseURL}${ENDPOINTS.CUSTOMER_LIST}`),
            axiosInstance.get(`${baseURL}${ENDPOINTS.CUSTOMER_PRODUCT_NAMES}`),
          ]);
        setFactoryProducts(productResponse?.data?.data);
        setCustomers(customerResponse?.data?.data);
        setCustomerProducts(customerProductNameResponse?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchOrder();
    fetchProductsAndCustomers();
  }, [orderId, baseURL, token, navigate]);

  useEffect(() => {
    const calculateTotalQuantity = () => {
      const total = variants.reduce(
        (acc, variant) => acc + (parseInt(variant.quantity) || 0),
        0
      );
      setTotalQuantity(total);
    };

    const calculateShippedQuantity = () => {
      const shipped = variants.reduce(
        (acc, variant) => acc + (parseInt(variant.shipped) || 0),
        0
      );
      setShippedQuantity(shipped);
    };

    calculateTotalQuantity();
    calculateShippedQuantity();
  }, [variants]);

  // Product Variant
  const handleVariantNameChange = (index, selectedOption) => {
    const updatedVariants = [...variants];
    updatedVariants[index].name = selectedOption?.label;
    setVariants(updatedVariants);
  };
  const handleAddVariant = () => {
    setVariants([...variants, { name: "", quantity: "", shipped: "" }]);
  };
  const handleRemoveVariant = (index) => {
    const updatedVariants = [...variants];
    updatedVariants.splice(index, 1);
    setVariants(updatedVariants);
  };
  const handleVariantQuantityChange = (index, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index].quantity = value;
    setVariants(updatedVariants);
  };
  const handleShippedVariantChange = (index, value) => {
    const updatedVariants = [...variants];
    if (parseInt(value) > parseInt(updatedVariants[index].quantity)) {
      alert("Shipped quantity exceeds variant quantity!");
      return;
    }
    updatedVariants[index].shipped = value;
    setVariants(updatedVariants);
  };

  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  const handleFactoryProductChange = (selectedOption) => {
    setSelectedFactoryProduct(selectedOption);
  };
  const handleCustomerProductChange = (selectedOption) => {
    setSelectedCustomerProduct(selectedOption);
  };

  const handleCreateOption = async (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setCustomerProducts([...customerProducts, newOption]);
    setSelectedCustomerProduct(newOption);

    try {
      const response = await axiosInstance.post(
        `${ENDPOINTS.CUSTOMER_PRODUCT_NAMES}`,
        { productName: inputValue }
      );

      console.log("New customer product added successfully:", response.data);
    } catch (error) {
      console.error("Error adding new customer product:", error);
    }
  };

  const handleShippedQuantityChange = (e) => {
    const newShippedQuantity = parseInt(e.target.value, 10);
    setShippedQuantity(newShippedQuantity);

    if (newShippedQuantity === totalQuantity) {
      setStatus("Completed");
    }
  };

  const handleUpdateOrder = async (e) => {
    e.preventDefault();

    if (new Date(promisedStartDate) > new Date(deadline)) {
      alert("Promised start date cannot surpass deadline!");
      return;
    }
    if (shippedQuantity > totalQuantity) {
      alert("Shipped quantity cannot exceed total quantity!");
      return;
    }

    try {
      const payload = {
        customerId: selectedCustomer.value,
        customerProductName: selectedCustomerProduct.value,
        factoryProductName: selectedFactoryProduct.value,
        promisedStartDate: format(parseISO(promisedStartDate), "dd-MM-yyyy"),
        deadline: format(parseISO(deadline), "dd-MM-yyyy"),
        productVariant: variants,
        totalQuantity: Number(totalQuantity),
        shippedQuantity: Number(shippedQuantity),
        status,
      };

      await axiosInstance.put(`${ENDPOINTS.ORDER_LIST}${orderId}`, payload);
      toast.success("Order updated successfully!");
      navigate("/orders");
    } catch (error) {
      console.error("Error updating order:", error);
      alert("Failed to update order. Please try again.");
    }
  };
  const handleKeyDown = (event, index) => {
    if (event.key === "Enter" && index === variants?.length - 1) {
      event.preventDefault();
      handleAddVariant();
    }
  };

  return (
    <div>
      <div className="mx-3">
        <button
          type="button"
          onClick={() => {
            navigate("/orders");
          }}
          className="ml-8 w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto"
        >
          <svg
            className="w-5 h-5 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>
          <span>Go back</span>
        </button>
      </div>

      <div className="px-40 py-4">
        <p className="text-2xl font-bold mb-4 text-gray-900">Edit Order</p>

        <form onSubmit={handleUpdateOrder}>
          <div className="d-flex gap-6">
            <div
              className="col-span-2 mb-3"
              style={{ width: "calc(100% - 40px)" }}
            >
              <label
                htmlFor="customer_name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Customer Name
              </label>
              <Select
                id="customer_name"
                options={customers.map((customer) => ({
                  value: customer._id,
                  label: customer.name,
                }))}
                value={selectedCustomer}
                onChange={handleCustomerChange}
                placeholder="Select a customer..."
                isSearchable
              />
            </div>
            <div
              className="grid gap-6 mb-3 md:grid-cols-2"
              style={{ width: "calc(100% - 40px)" }}
            >
              <div>
                <label
                  htmlFor="customer_product_name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Customer Product Name
                </label>
                <CreatableSelect
                  id="customer_product_name"
                  options={customerProducts.map((product) => ({
                    value: product.productName,
                    label: product.productName,
                  }))}
                  value={selectedCustomerProduct}
                  onChange={handleCustomerProductChange}
                  onCreateOption={handleCreateOption}
                  placeholder="Select or type a customer product name..."
                  isSearchable
                />
              </div>

              <div>
                <label
                  htmlFor="factory_product_name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Factory Product Name
                </label>
                <Select
                  id="factory_product_name"
                  options={factoryProducts.map((product) => ({
                    value: product.productName,
                    label: product.productName,
                  }))}
                  value={selectedFactoryProduct}
                  onChange={handleFactoryProductChange}
                  placeholder="Select a product..."
                  isSearchable
                />
              </div>
            </div>
            <div
              className="grid gap-6 mb-3 md:grid-cols-2"
              style={{ width: "calc(100% - 40px)" }}
            >
              <div>
                <label
                  htmlFor="promised_start_date"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  {selectedCustomer?.label === "self production"
                    ? "start date"
                    : "Promised Start Date"}
                </label>
                <input
                  value={promisedStartDate}
                  onChange={(e) => {
                    setStart(e.target.value);
                  }}
                  type="date"
                  id="promised_start_date"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder=""
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="deadline"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  {selectedCustomer?.label === "self production"
                    ? "end date"
                    : "Promised Deadline"}
                </label>
                <input
                  value={deadline}
                  onChange={(e) => {
                    setDeadline(e.target.value);
                  }}
                  type="date"
                  id="deadline"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder=""
                  required
                />
              </div>
            </div>

            {/* // Product Variant // */}
            <div className="mt-2 col-span-2">
              <div
                className="grid grid-cols-3"
                style={{ width: "calc(100% - 40px)" }}
              >
                <label htmlFor="variantName" className="mb-1">
                  Colour
                </label>
                <label htmlFor="variantQuantity" className="mb-1">
                  Quantity
                </label>
                <label htmlFor="variantShipped" className="mb-1">
                  Shipped
                </label>
              </div>

              {variants.map((variant, index) => (
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  key={index}
                >
                  <div
                    className="grid grid-cols-3 gap-2 mb-2"
                    style={{ width: "calc(100% - 40px)" }}
                  >
                    <CreateableSelectWithDelete
                      getArticlesUrl={`${baseURL}${ENDPOINTS.VARIANT_NAME_LIST}`}
                      deleteArticleUrl={`${baseURL}${ENDPOINTS.VARIANT_NAME_DELETE}`}
                      addArticleUrl={`${baseURL}${ENDPOINTS.ADD_VARIANT_NAME}`}
                      token={token}
                      keyProp="variantName"
                      value={variant?.name}
                      onChange={(selectedOption) =>
                        handleVariantNameChange(index, selectedOption)
                      }
                      placeholder="Select Variant Name..."
                    />

                    <input
                      value={variant.quantity}
                      onChange={(e) => {
                        handleVariantQuantityChange(index, e.target.value);
                      }}
                      type="number"
                      id="variantQuantity"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder=""
                      required
                      onKeyDown={(event) => handleKeyDown(event, index)}
                    />

                    <div className="flex">
                      <input
                        value={variant.shipped}
                        onChange={(e) => {
                          handleShippedVariantChange(index, e.target.value);
                        }}
                        type="number"
                        id="variantQuantity"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder=""
                        required
                        onKeyDown={(event) => handleKeyDown(event, index)}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "40px",
                    }}
                  >
                    {variants.length > 1 && (
                      <button
                        onClick={() => handleRemoveVariant(index)}
                        type="button"
                        className="m-2 border-2 border-gray-700 hover:bg-slate-200 hover:text-white font-medium rounded-full text-sm p-1 w-6 h-6"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13"
                          height="13"
                          viewBox="0 0 24 24"
                        >
                          <path fillRule="evenodd" d="M2 11h20v2H2z"></path>
                        </svg>
                      </button>
                    )}
                    {index === variants?.length - 1 && (
                      <button
                        onClick={handleAddVariant}
                        type="button"
                        className="m-2 border-2 border-gray-700 hover:bg-slate-200 hover:text-white font-medium rounded-full text-sm p-1 w-6 h-6"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13"
                          height="13"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
                          ></path>
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div
              className="grid gap-6 mb-3 md:grid-cols-2"
              style={{ width: "calc(100% - 40px)" }}
            >
              <div>
                <label
                  htmlFor="totalQuantity"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Total Quantity
                </label>
                <input
                  value={totalQuantity}
                  onChange={(e) => {
                    setTotalQuantity(e.target.value);
                  }}
                  type="number"
                  id="totalQuantity"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder=""
                  disabled
                />
              </div>
              <div>
                <label
                  htmlFor="shippedQuantity"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Shipped Quantity
                </label>
                <input
                  value={shippedQuantity}
                  onChange={handleShippedQuantityChange}
                  type="number"
                  id="shippedQuantity"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder=""
                  required
                />
              </div>
            </div>
            <div
              className="grid gap-6 mb-3 md:grid-cols-2"
              style={{ width: "calc(100% - 40px)" }}
            >
              <div>
                <label
                  htmlFor="status"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Status
                </label>
                <select
                  id="status"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  disabled={shippedQuantity === totalQuantity}
                >
                  <option value="">Select Status...</option>
                  {shippedQuantity === 0 && (
                    <>
                      <option value="Not Started">Not Started</option>
                      <option value="In Production">In Production</option>
                    </>
                  )}
                  {shippedQuantity > 0 && (
                    <>
                      <option value="In Production">In Production</option>
                      <option value="Cancelled">Cancelled</option>
                    </>
                  )}
                  {shippedQuantity === totalQuantity && (
                    <option value="Completed">Completed</option>
                  )}
                </select>
              </div>
            </div>
          </div>

          <div className=" flex justify-center">
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            >
              Update Order
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditOrder;
