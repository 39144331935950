import axios from "axios";
import Cookies from "js-cookie"; // Import js-cookie
import { logout } from "../utils/index";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,

  headers: {
    "Content-Type": "application/json",
    accessToken: Cookies.get("token"),

    // "Access-Control-Allow-Origin": "*",
  },
});

export const setupResponseInterceptor = (setError) => {
  console.log("SETTING UP INTERCEPTOR");
  console.log("🚀 ~ setError:", setError);
  axiosInstance.interceptors.request.use(
    async (config) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response.data.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = Cookies.get("refreshToken");

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/login/refreshToken`,
            { refreshToken }
          );
          const { accessToken, refreshToken: newRefreshToken } =
            response.data.data;
          console.log("🚀 ~ response:", response);

          Cookies.set("accessToken", accessToken, { expires: 30 });
          Cookies.set("refreshToken", newRefreshToken, { expires: 7 });

          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          console.error("Refresh token failed:", refreshError.response.status);
          console.error(
            "Refresh token failed - response:",
            refreshError.response
          );

          // Handle refresh token failure (e.g., log out the user)
          if (refreshError.response.status === 400) {
            console.log("🚀 ~ setError: STATUS 400", setError);
            logout();
            setError("Session expired. Please login again.");
            console.log("🚀 ~ navigating:");
          }
        }
      }

      return Promise.reject(error);
    }
  );
};

export default axiosInstance;

