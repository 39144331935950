import {useQuery, useMutation } from "react-query";
import axiosInstance from "../../service/axiosInstance";
import ENDPOINTS from "../../service/endpoint";


const fetchRawMaterial = async (params) => {
    console.log("🚀 ~ fetchProductionPlan ~ params:", params)
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.ADD_RAW_MATERIAL,
      headers: { "Content-Type": "application/json" },
      data: params.payload,
    });
    return data;
  };
  
  const useRawMaterial = () => {
    return useMutation({
      
      queryKey: ["useRawMaterial"],
      mutationFn: (params) => fetchRawMaterial(params),
    });
  };

  const fetchRawMaterialList = async (params) => {
    const { data } = await axiosInstance({
      method: "get",
      url: ENDPOINTS.RAW_MATERIAL_LIST,
      headers: { "Content-Type": "application/json" },
    });
    return data;
  };
  
  const useRawMaterialList = () => {
    return useMutation({
      
      queryKey: ["useRawMaterialList"],
      mutationFn: (params) => fetchRawMaterialList(params),
    });
  };
  const fetchSingleRawMaterial = async (params) => {
    console.log("🚀 ~ fetchProductionPlan ~ params:", params)
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.SINGLE_RAW_MATERIAL,
      headers: { "Content-Type": "application/json" },
      data: params,
    });
    return data;
  };
  
  const useSingleRawMaterial = () => {
    return useMutation({
      
      queryKey: ["useSingleRawMaterial"],
      mutationFn: (params) => fetchSingleRawMaterial(params),
    });
  };


  // edit production plan

  const editRawMaterial = async (params) => {
    console.log("🚀 ~ fetchProductionPlan ~ params:", params)
    const { data } = await axiosInstance({
      method: "put",
      url: `${ENDPOINTS.EDIT_RAW_MATERIAL}${params?.id?.id}`,
      headers: { "Content-Type": "application/json" },
      data: params.rawMaterial,
    });
    return data;
  };
  
  const useRawMaterialUpdate = () => {
    return useMutation({
      
      queryKey: ["useRawMaterialUpdate"],
      mutationFn: (params) => editRawMaterial(params),
    });
  };


  // delete production plan

  const deleteRawMaterial = async (id) => {
   console.log("🚀 ~ deleteRawMaterial ~ id:", id)
   
    const { data } = await axiosInstance({
      method: "delete",
      url: `${ENDPOINTS.DELETE_RAW_MATERIAL}${id}`,
      headers: { "Content-Type": "application/json" },
    });
    return data;
  };
  
  const useDeleteRawMaterial = () => {
    return useMutation({
      queryKey: ["useDeleteRawMaterial"],
      mutationFn: ({id}) => deleteRawMaterial(id),
    });
  };

  export { 
    useRawMaterial,
    useRawMaterialList,
    useSingleRawMaterial,
    useRawMaterialUpdate,
    useDeleteRawMaterial,
   };
