import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, ReactTooltip } from "react-tooltip";
import "./tooltip.css";
import { IoMdContacts } from "react-icons/io";
import {
  useGetProductionIncharge,
  useDeleteProductionIncharge,
} from "./hooks/productionIncharge";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

const ProductionIncharge = () => {
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [production, setProduction] = useState([]);
  const [lineInfo, setLineInfo] = useState([]);
  const {
    mutate,
    data,
    isLoading,
    isSuccess,
    isError: productionError,
    error: productionerror,
  } = useGetProductionIncharge();
  const {
    mutateAsync,
    isSuccess: deletesuccess,
    isError,
    error,
  } = useDeleteProductionIncharge();

  const handleDelete = async (product) => {
    if (window.confirm(`Are you sure you want to delete ${product.name}?`)) {
      try {
        const response = await mutateAsync({
          userId: product?.userId,
          id: product?._id,
        });
        toast.success("Deleted Successfully!");

        setProduction((prev) =>
          prev.filter((item) => item?._id !== product?._id)
        );
      } catch (isError) {
        console.error("Failed to delete the production incharge", error);
        toast.error(error.response.data.message);
        
      }
    }
  };
  useEffect(() => {
    if (!isSuccess) {
      mutate();
    }

    if (productionError) {
      toast.error(productionerror.response.data.message);
      
    }
  }, [isSuccess, productionError, productionerror, mutate, navigate]);

  useEffect(() => {
    if (data?.data?.data) {
      setProduction(data?.data?.data);
    }
  }, [data]);

  const handleMouseEnter = (index, selectLI) => {
    setHoveredIndex(index);
    setLineInfo(selectLI);
    setShowTooltip(false);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
    setShowTooltip(true);
  };

  const handleEdit = (product) => {
    navigate("/pi/editpi", { state: product });
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
            width: "80vw",
          }}
        >
          <ClipLoader size={100} />
        </div>
      ) : (
        <div className="m-9">
          <div
            className="piheader"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <h2 className="text-3xl font-extrabold tracking-tight text-slate-900 mb-7">
              {" "}
              Production Incharge
            </h2>

            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto mb-7 px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={() => navigate("/pi/addpi")}
              style={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              Add Production Incharge
            </button>
          </div>

          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  PI Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  No. Of Lines
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Phone No.
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Delete</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {production?.map((product, index) => (
                <tr
                  key={index}
                  data-tooltip-id={`tooltip-${index}`}
                  style={{
                    background: index % 2 === 0 ? "#fafafa" : "#8fa6ea",
                  }}
                >
                  <td
                    className="px-6 py-3 whitespace-nowrap"
                    onMouseEnter={() =>
                      handleMouseEnter(index, product.selectLI)
                    }
                    onMouseLeave={handleMouseLeave}
                  >
                    {product?.name}
                  </td>
                  <td
                    className="px-6 py-3 whitespace-nowrap"
                    onMouseEnter={() =>
                      handleMouseEnter(index, product.selectLI)
                    }
                    onMouseLeave={handleMouseLeave}
                  >
                    {product?.selectLI?.length}
                  </td>
                  <td
                    className="px-6 py-3 whitespace-nowrap"
                    onMouseEnter={() =>
                      handleMouseEnter(index, product.selectLI)
                    }
                    onMouseLeave={handleMouseLeave}
                  >
                    {product.phone}
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                    <button
                      className="text-blue-600 hover:text-blue-900"
                      onMouseEnter={() =>
                        handleMouseEnter(index, product.selectLI)
                      }
                      onMouseLeave={handleMouseLeave}
                      onClick={() => {
                        handleEdit(product);
                      }}
                    >
                      Edit
                    </button>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                    <button
                      className="text-red-600 hover:text-red-900"
                      onMouseEnter={() =>
                        handleMouseEnter(index, product.selectLI)
                      }
                      onMouseLeave={handleMouseLeave}
                      onClick={() => {
                        handleDelete(product);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                  <Tooltip
                    id={`tooltip-${index}`}
                    place="top"
                    effect="solid"
                    className="custom-tooltip"
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginBottom: "5px",
                        }}
                      >
                        <p style={{ width: "100px" }}> Line Incharge</p>
                        <p style={{ width: "110px", textAlign: "left" }}>
                          Assembly Line
                        </p>
                      </div>
                      {lineInfo.length > 0 ? (
                        lineInfo.map((line, i) => (
                          <div
                            key={i}
                            style={{ marginBottom: "5px", display: "flex" }}
                          >
                            <p style={{ width: "110px" }}> {line.nameLI}</p>
                            <p style={{ width: "110px" }}> {line.LINO}</p>
                          </div>
                        ))
                      ) : (
                        <div>No data available</div>
                      )}
                    </div>
                  </Tooltip>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ProductionIncharge;
