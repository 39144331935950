import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';  // Import PropTypes

const StateContext = createContext();

const initialState = {
  userProfile: false,
};

// export const useAuth = () => {
//   return useContext(StateContext);
// };

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);

  const [currentColor, setCurrentColor] = useState('#1D4ED7');
  const [currentMode, setCurrentMode] = useState('Light');

  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  
  const [user, setUser] = useState(
    localStorage.getItem('token') ? true : false
  );
  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem('themeMode', e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor('#1D4ED7');
    localStorage.setItem('colorMode', color);
  };
  
  // const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: !prevState[clicked] });
  const handleClick = (clicked) => {
    setIsClicked((prevState) => ({
      ...initialState,
      [clicked]: !prevState[clicked],
    }));
  };



  return (
    <StateContext.Provider value={{ currentColor, currentMode, activeMenu, screenSize, setScreenSize, handleClick, isClicked, initialState, setIsClicked, setActiveMenu, setCurrentColor, setCurrentMode, setMode, setColor, themeSettings, setThemeSettings,user, setUser }}>
      {children}
    </StateContext.Provider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,  // Add PropTypes validation
};

export const useStateContext = () => useContext(StateContext);
