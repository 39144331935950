import React, { useEffect, useState } from 'react'
import { useProductionDetails, useProductionStatus } from '../hooks/wareHouse'
import { Accordian } from '../../components'
import { ClipLoader } from 'react-spinners'
import {capitalizeFirstLetter} from '../../utils/index'

const ProductionDetails = () => {
    const [productionDetails, setProductionDetails] = useState([])
    const { data:production, isLoading}=useProductionDetails()
    const [openAccordionIndex, setOpenAccordionIndex] = useState(-1);
    const {mutateAsync}=useProductionStatus()


    useEffect(() => {
        setProductionDetails(production?.data?.data)
    }, [production])
    
    const toggleAccordion = (index) => {
      setOpenAccordionIndex(openAccordionIndex === index ? -1 : index);
    };
      
    const handleCollected =(newStatus, id)=>{
      const confirmUpdate = window.confirm(`Are you sure you want to change the Outbound Status to Collected?`);
    if (confirmUpdate) {
      mutateAsync({
        id: id,
        status: {
          status: newStatus
        }
      });
    }
  };
  return (
    <div className="mx-auto">
      <div className='flex justify-around p-4 mb-2 bg-gray-200 text-center pr-10 '> 
        <p className='w-1/5'>Date</p>
        <p className='w-1/5'>LI Name</p>
        <p className='w-1/5'>Assembly Line</p>
        <p className='w-1/5'>Related Product</p>
        {/* <p className='w-1/5'>Quantity</p> */}
        <p className='w-1/5'>Status</p>

      </div>
    { isLoading ?(
      <div className='' style={{display:'flex',justifyContent:'center'}}>
    <ClipLoader size={100}/>
</div>
    ):(
    
        productionDetails?.map((item, index) => {
          const date =new Date(item?.createdAt)
          return(
      <Accordian
        key={item._id}
        titleItems={[date.toDateString(), item?.nameLI, item?.selectLine, item?.finishedProduct, (item?.status === 'collection_request' ? 'Collection Request' : item?.status === 'received' ? 'Collected' : '')]}
        isOpen={openAccordionIndex === index}
        onClick={() => toggleAccordion(index)}
      >
        <div className="flex justify-center items-center gap-5 mb-4">
              {/* <label htmlFor={`statusSelect-${item._id}`} className="block font-medium mb-1">
                Select Status:
              </label> */}
              
              {/* <select
                id={`statusSelect-${item._id}`}
                onChange={(e) => handleStatusChange(e.target.value, item._id)}
                className="p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 "
                style={{width:'200px'}}
              >
               <option value="">Change Status</option>
                <option value="collection_request">Collection_Request</option>
                <option value="received">Received</option>
              </select> */}
              {item?.status != 'received' &&(
              <button type='button' onClick={() => handleCollected('received', item._id)}
            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${item?.status === 'received' ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={item?.status === 'received'}                >
                Mark Collected
              </button>
              )}
            </div>
        <table className="min-w-full divide-y text-left">
          <thead>
            <tr >
              <th className="border px-4 py-2 w-1/3">Product/SKU Name</th>
              <th className="border px-4 py-2 w-1/3">Finshed Quantity / Quantity </th>
              <th className="border px-4 py-2 w-1/3">Defective Quantity</th>


            </tr>
          </thead>
          <tbody>
            <tr className='font-semibold bg-slate-300'>
              <td className="border px-4 py-2 w-1/3">{item?.finishedProduct}</td>
              <td className="border px-4 py-2 w-1/3">{item?.finishedProductQuantity}</td>
              <td className="border px-4 py-2 w-1/3">{item?.defectiveQuantity ? item?.defectiveQuantity :0 }</td>
            </tr>
            {item?.rawMaterial?.map(material => (
              <tr key={material._id}>
                <td className="border px-4 py-2 w-1/3">{material?.materialName}</td>
                <td className="border px-4 py-2 w-1/3">{material?.quantity}</td>
                <td className="border px-4 py-2 w-1/3">{material?.defectiveQuantity ? material?.defectiveQuantity :0 }</td>

              </tr>
            ))}
          </tbody>
        </table>
      </Accordian>
    )})
)
}
  </div> 
   )
}


export default ProductionDetails