import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader"; // Import the Loader component
import Cookies from "js-cookie"; // Import js-cookie
import ENDPOINTS from "../service/endpoint";
import axiosInstance from "../service/axiosInstance"; // Import the axiosInstance

const ProductMaster = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = Cookies.get("accessToken");

  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchProducts = async () => {
    try {
      const response = await axiosInstance.get(ENDPOINTS.PRODUCT_LIST);
      setProducts(response?.data?.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false); // Set loading to false after data fetch is complete
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleDelete = async (productId) => {
    // Ask for confirmation before deleting
    const confirmed = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (!confirmed) {
      return; // If user cancels, do nothing
    }

    try {
      await axiosInstance.delete(`${ENDPOINTS.PRODUCT_LIST}${productId}`);
      // Filter out the deleted product from the current state
      const updatedProducts = products.filter(
        (product) => product._id !== productId
      );
      setProducts(updatedProducts);
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  return (
    <div className="mx-9 px-9 mt-2">
      <div className="flex justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-slate-900 mb-7">
          Product Master
        </h2>
        <button
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto mb-7 px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={() => navigate("/product/addproduct")}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          Add Product
        </button>

        {/* <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto mt-5 px-5 py-2.5 text-center"
              onClick={() => navigate('/addproduct')}
            >
              Add Product
            </button> */}
      </div>

      {loading ? (
        <Loader /> // Use the Loader component here
      ) : (
        <div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Factory Product Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Quantity
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Delete</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {products.map((product, index) => (
                <tr
                  key={product._id}
                  className={index % 2 === 0 ? "bg-white" : "bg-indigo-300"}
                >
                  <td className="px-6 py-3 whitespace-nowrap">
                    {product?.productName}
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    {product?.category?.category}
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    {product?.maxProducts}
                  </td>

                  <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                    <button
                      className="text-blue-600 hover:text-blue-900"
                      onClick={() =>
                        navigate(`/product/editproduct/${product?._id}`)
                      }
                    >
                      Edit
                    </button>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                    <button
                      className="text-red-600 hover:text-red-900"
                      onClick={() => handleDelete(product?._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ProductMaster;
