import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client"; // Import createRoot from react-dom/client

import "./index.css";
import App from "./App";
import { ContextProvider } from "./contexts/ContextProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ErrorProvider } from "./contexts/ErrorProvider";

const queryClient = new QueryClient();

// Use createRoot from react-dom/client
const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ContextProvider>
        <ErrorProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ErrorProvider>
      </ContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
