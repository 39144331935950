import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomerList } from "../hooks/customer";
import { useOrderList } from "../hooks/order";
import { useAssemblyLineList } from "../hooks/assemblyline";
import { BeatLoader, ClipLoader } from "react-spinners";
import { addDays, format, parse } from "date-fns"; // Import date-fns for date calculations
import {
  productionplanvalidationSchema,
  initialValuesProductionPlan,
} from "../../validation";
import { useFormik } from "formik";
import { useEditProductionPlan, useSingleProductionPlan } from "../hooks/productionPlan";
import { toast } from "react-toastify";
const EditProductionPlan = () => {
  const navigate = useNavigate();
  const [customerList, setCustomerList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [assemblyLineList, setAssemblyLineList] = useState([]);
  const [initialValue, setInitialValue] = useState(null)
  const id = useParams()
  const [startDateLabel, setStartDateLabel] = useState("Promised Start Date");
  const [endDateLabel, setEndDateLabel] = useState("Promised End Date");

  const prevCustomerId = useRef(null);

  const { data, isLoading, isSuccess, isError, error } =
    useCustomerList();
  const {
    data: order,
    isLoading: orderLoading,
    mutate: ordermutate,
    isSuccess: orderSuccess,
    isError: orderError,
    error: ordererror,
  } = useOrderList();
  const {
    data: assemblyLine,
    isLoading: assemblyLineLoading,
    mutate: assemblyLinemutate,
    isSuccess: assemblyLineSuccess,
    isError: assemblyLineError,
    error: assemblyLineerror,
  } = useAssemblyLineList();

  const {
    data: productionPlanData,
    isLoading: productionLoading,
    mutateAsync,
    isSuccess: productionSuccess,
    isError: productionError,
    error: productionerror,
  } = useEditProductionPlan();

  const {
    data: singleproductionPlanData,
    isLoading: singleproductionLoading,
    mutateAsync:singlemutateasync,
    isSuccess: singleproductionSuccess,
    isError: singleproductionError,
    error: singleproductionerror,
  } = useSingleProductionPlan();
    console.log("🚀 ~ EditProductionPlan ~ singleproductionPlanData:", singleproductionPlanData?.data?.productionPlan)

  useEffect(() => {
    singlemutateasync({
      id:id?.id
    });

    if (singleproductionError) {
      toast.error(singleproductionerror.response.data.message);
     

      if (singleproductionerror.response.data.status === 400) {
        navigate("/plan");
      }

    }
  }, [id]);

  useEffect(() => {
    if (singleproductionSuccess) {
      setInitialValue(singleproductionPlanData?.data?.productionPlan);
    }
  }, [singleproductionPlanData, singleproductionSuccess])
  

  useEffect(() => {
    // if (!isSuccess) {
    //   mutate();
    // }

    if (isError) {
      toast.error(error.response.data.message);
      
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!assemblyLineSuccess) {
      assemblyLinemutate();
    }

    if (assemblyLineError) {
      toast.error(assemblyLineerror?.response.data.message);
      
    }
  }, [assemblyLineSuccess]);

  useEffect(() => {
    setCustomerList(data?.data);
    setOrderList(order?.data);
    setAssemblyLineList(assemblyLine);
  }, [data, order, assemblyLine]);

  useEffect(() => {
    if (productionSuccess) {
      const { status, message } = productionPlanData;
      console.log("🚀 ~ useEffect ~ message:", message);
      toast.success(message);

      if (status === 200) {
        resetForm();
        navigate("/plan");
      }
    }

    if (productionError) {
      toast.error(productionerror.response.data.message);
      console.log("🚀 ~ useEffect ~ error:", error);
      
    }
  }, [productionError, productionSuccess, productionPlanData, productionerror]);

  const {
    resetForm,
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      customerId: initialValue?.customerId || '',
      orderId: initialValue?.orderId || '',
      assignAssemblyLineId: initialValue?.assignAssemblyLineId || '',
      averageDailyProduction: initialValue?.averageDailyProduction || '',
      startDate: initialValue?.startDate || '',
      projectedEndDate: initialValue?.projectedEndDate || '',
    },
    enableReinitialize: true, // Enable reinitializing the form
    validationSchema: productionplanvalidationSchema,
    onSubmit: async (values) => {
      try {
        const startDate = format(parse(values.startDate, 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd');
    const projectedEndDate = format(parse(values.projectedEndDate, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
        const payload = {
          customerId: values.customerId,

          orderId: values.orderId,
          assignAssemblyLineId: values.assignAssemblyLineId,
          averageDailyProduction: values.averageDailyProduction,
          startDate: startDate,
          projectedEndDate:projectedEndDate,
        };
        console.log("🚀 ~ onSubmit: ~ payload:", payload);
        const response = await mutateAsync({ payload, id:id });
      } catch (error) {
        console.error("Error during login:", error);
      }
    },
  });
    console.log("🚀 ~ EditProductionPlan ~ values:", values)
  console.log("🚀 ~ AddProductionPlan ~ values:", values);

  
  useEffect(() => {
    if (values.customerId && values.customerId !== prevCustomerId.current) {
      ordermutate({ id: values.customerId });
      prevCustomerId.current = values.customerId; // Update the previous customerId

     
      // setStartDateLabel(values.customerId === "selfproduction" ? "Start Date" : "Promised Start Date");
      // setEndDateLabel(values.customerId === "selfproduction" ? "End Date" : "Promised End Date");

    }
  
    if (orderError) {
      console.log("🚀 ~ useEffect ~ orderError:", ordererror?.response)
      toast.error(ordererror.response.message);
     
    }
  }, [customerList, values?.customerId, orderSuccess]);

  useEffect(() => {
    if (customerList) {
      const selectedCustomer = customerList.find(customer => customer._id === values.customerId);
      console.log("🚀 ~ useEffect ~ values.customerId:", values.customerId)
      console.log("🚀 ~ useEffect ~ customerList:", customerList)
      console.log("🚀 ~ useEffect ~ selectedCustomer:", selectedCustomer)
      if (selectedCustomer?.name === "self production") {
        setStartDateLabel("Start Date");
        setEndDateLabel("End Date");
      } else {
        setStartDateLabel("Promised Start Date");
        setEndDateLabel("Promised End Date");
      }
    }
  }, [customerList, values?.customerId ])
  


  const getSelectedOrderDetails = () => {
    if (!values?.orderId) return null;
    return orderList?.find((order) => order._id === values?.orderId);
  };


  const selectedOrderDetails = getSelectedOrderDetails();
  console.log(
    "🚀 ~ AddProductionPlan ~ selectedOrderDetails:",
    selectedOrderDetails
  );

  const addBusinessDays = (startDate, days) => {
    let result = new Date(startDate);
    let dayCount = 1;
    while (dayCount < days) {
      result = addDays(result, 1);
      if (result.getDay() !== 0) {
        dayCount++;
      }
    }
    return result;
  };
  useEffect(() => {
    if (
      values.startDate &&
      selectedOrderDetails?.totalQuantity &&
      values?.averageDailyProduction > 0
    ) {
      const totalDays = Math.ceil(
        selectedOrderDetails.totalQuantity / values?.averageDailyProduction
      );
      const endDate = addBusinessDays(new Date(values?.startDate), totalDays);

      // projectedEndDate(format(endDate, "yyyy-MM-dd"));
      setFieldValue("projectedEndDate", format(endDate, "dd-MM-yyyy"));
    }
  }, [values?.startDate, selectedOrderDetails, values?.averageDailyProduction]);

  const handleCustomerChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "createcustomer") {
      navigate(`/plan/${'addproductionplan'}/addcustomer`);
    } else {
      handleChange(e);
      
      
    }
  };
  return (
    <>
    {(singleproductionLoading && isLoading) ?(
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', width: '80vw' }}>
     <ClipLoader size={100} />
   </div>
    ):(
    <div>
      <div className="mx-3 mt-7">
        <button
          type="button"
          onClick={() => {
            navigate("/plan");
          }}
          className="ml-8 mt-3 w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
        >
          <svg
            className="w-5 h-5 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>
          <span>Go back</span>
        </button>
      </div>

      <div className="px-40 py-10" style={{ paddingTop: "10px" }}>
        <h2
          className="text-3xl font-extrabold tracking-tight text-slate-900 mb-7"
          style={{}}
        >
          Edit Production Plan
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <label
                htmlFor="first_name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Customer Name/Self-Production*
              </label>
              <select
                id="customerId"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={values.customerId}
                onChange={handleCustomerChange}
              >
                 <option value="">Select Customer/Self-Production</option>
                <option value="createcustomer">Create Customer</option>
                {/* <option value="selfproduction">Self Production</option> */}
                {isLoading ? (
                  <option>
                    <ClipLoader size={40} />
                  </option>
                ) : (
                  customerList?.map((customer) => (
                    <option key={customer?._id} value={customer?._id}>
                      {customer?.name}
                    </option>
                  ))
                )}
              </select>
              {touched.customerId && errors.customerId && (
                <p className="text-red-500">{errors.customerId}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="last_name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Order Name*
              </label>
              <select
                id="orderId"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={values.orderId}
                onChange={handleChange}
              >
                <option value="">Select a Order</option>
                {orderLoading ? (
                  <option>
                    <ClipLoader size={40} />
                  </option>
                ) : (
                  orderList?.map((order) => (
                    <option key={order?._id} value={order?._id}>
                      {order?.customerProductName}
                    </option>
                  ))
                )}
              </select>
              {touched.orderId && errors.orderId && (
                <p className="text-red-500">{errors.orderId}</p>
              )}
            </div>
          </div>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <p style={{}}>
                {startDateLabel}:{" "}
                <span style={{ fontWeight: "700" }}>
                  {selectedOrderDetails?.promisedStartDate
                    ? selectedOrderDetails?.promisedStartDate
                    : "..."}
                </span>
              </p>
            </div>
            <div>
              <p style={{}}>
                {endDateLabel}:{" "}
                <span style={{ fontWeight: "700" }}>
                  {selectedOrderDetails?.deadline
                    ? selectedOrderDetails?.deadline
                    : "..."}
                </span>
              </p>
            </div>
            <div>
              <p style={{}}>
                Factory Product name:{" "}
                <span style={{ fontWeight: "700" }}>
                  {" "}
                  {selectedOrderDetails?.factoryProductName
                    ? selectedOrderDetails?.factoryProductName
                    : "..."}
                </span>
              </p>
            </div>
            <div>
              <p style={{}}>
                Total Quantity:{" "}
                <span style={{ fontWeight: "700" }}>
                  {" "}
                  {selectedOrderDetails?.totalQuantity
                    ? selectedOrderDetails?.totalQuantity
                    : "..."}
                </span>
              </p>
            </div>
            <div>
              <p style={{}}>
                Raw material available for quantity:{" "}
                <span style={{ fontWeight: "700" }}> XYZ</span>
              </p>
            </div>
          </div>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <label
                htmlFor="company"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Assign Assembly Line*
              </label>
              <select
                id="assignAssemblyLineId"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={values.assignAssemblyLineId}
                onChange={handleChange}
              >
                <option value="">Select a product...</option>
                {assemblyLineLoading ? (
                  <option>
                    <ClipLoader size={40} />
                  </option>
                ) : (
                  assemblyLineList?.map((assembly) => (
                    <option key={assembly?._id} value={assembly?._id}>
                      {assembly?.lineName}
                    </option>
                  ))
                )}
              </select>
              {touched.assignAssemblyLineId && errors.assignAssemblyLineId && (
                <p className="text-red-500">{errors.assignAssemblyLineId}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="company"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Average daily Production*
              </label>
              <input
                type="text"
                id="company"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder=""
                name="averageDailyProduction"
                value={values.averageDailyProduction}
                onChange={handleChange}
              />
              {touched.averageDailyProduction &&
                errors.averageDailyProduction && (
                  <p className="text-red-500">
                    {errors.averageDailyProduction}
                  </p>
                )}
            </div>
            <div>
              <label
                htmlFor="company"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Start Date*
              </label>
              <input
                type="date"
                id="company"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder=""
                name="startDate"
                value={values.startDate}
                onChange={handleChange}
              />
              {touched.startDate && errors.startDate && (
                <p className="text-red-500">{errors.startDate}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="company"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Projected End Date*
              </label>
              <input
                type="text"
                id="company"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder=""
                name="projectedEndDate"
                value={values.projectedEndDate}
                onChange={handleChange}
                readOnly
              />

              {touched.projectedEndDate && errors.projectedEndDate && (
                <p className="text-red-500">{errors.projectedEndDate}</p>
              )}
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              disabled={productionLoading}
            >
              {productionLoading ? (
                <BeatLoader size={8} color={"#ffffff"} />
              ) : (
                "Edit Productiom Plan"
              )}
            </button>
          </div>{" "}
        </form>
      </div>
    </div>
    )}
    </>
  );
};

export default EditProductionPlan