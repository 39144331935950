import React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Toolbar } from '@syncfusion/ej2-react-grids';
import { orderDetailsData, orderRawMaterialsData, orderRawMaterialsGrid, contextMenuItems,  } from '../data/dummy';
import { Header } from '../components';
import { useNavigate } from 'react-router-dom';

const OrderDetails = () => {
  const editing = { allowDeleting: true, allowEditing: true };
  const navigate= useNavigate();

  return (
    <div>
      <div className='mx-3 mt-7'>
        <button type="button" onClick={()=> {navigate('/dashboard')}} className="ml-8 mt-3 w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto">
            <svg className="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
            </svg>
            <span>Go back</span>
        </button>
      </div>

      <div className="mt-3 flex flex-wrap lg:flex-nowrap justify-center ">
        <div className="flex flex-wrap justify-evenly gap-4 items-center">
          {orderDetailsData.map((item) => (
            <div key={item.title} className="bg-gray-100 h-34 md:w-56 p-4 rounded-2xl shadow-md">
              <p className="font-bold">{item.title}</p>
              <p className="mt-2">
                <span className="text-lg ml-1 font-semibold">{item.amount}</span>
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="m-2 md:m-10 p-2 md:p-10 w-100 bg-white rounded-3xl">
        <Header category="" title="Raw Materials Available" />
        <GridComponent
            id="gridcomp"
            dataSource={orderRawMaterialsData}
            allowPaging
            allowSorting
            allowExcelExport
            allowPdfExport 
            contextMenuItems={contextMenuItems}
            editSettings={editing}
            toolbar={['Search']}
        >  
            <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {orderRawMaterialsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
            </ColumnsDirective>
            <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport, Toolbar]} />
        </GridComponent>
      </div>
   
      <div className='bg-gray-100 m-auto md:w-56 p-4 rounded-2xl shadow-md'>
          <div className='text-xl font-bold'>Livestream</div>
          
          <div className='mt-2'>            
            <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center" type="button">Choose Station <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
            </svg>
            </button>

            <div id="dropdown" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                <ul className="py-2 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
                  <li>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100">Station 1</a>
                  </li>
                  <li>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 ">Station 2</a>
                  </li>
                  <li>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 ">Station 3</a>
                  </li>
                  <li>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 ">Station 4</a>
                  </li>
                </ul>
            </div>
          
            <div className='bg-slate-200 h-40 mt-4 p-2 rounded-2xl text-center '>
              Live Feed
            </div>
          </div>

       
      </div>

    </div>
  );
};

export default OrderDetails;
