import React, { useEffect, useState } from 'react';
import {
  dropdownData,
} from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import TabbedView from "../components/TabbedView";
import PropTypes from "prop-types";
import axiosInstance from '../service/axiosInstance'; // Import the axiosInstance
import { LineChart, Loader } from "../components";
import { useNavigate } from "react-router-dom";
import ENDPOINTS from "../service/endpoint";

const Dashboard = () => {
  const navigate = useNavigate();
  const [totalOrdersQuantities, setTotalOrdersQuantities] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axiosInstance.get(ENDPOINTS.TOTAL_ORDERS_QUANTITIES);
        setTotalOrdersQuantities(response?.data?.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
       
        
      } finally {
        setLoading(false); // Set loading to false after data fetch is complete
      }
    };

    fetchOrders();
  }, []);

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="loader">
            <Loader />
          </div>
        </div>
      ) : (
        <div>
          {totalOrdersQuantities ? (
            <div className="flex flex-wrap lg:flex-nowrap justify-center">
              <div className="flex flex-wrap justify-evenly gap-4 items-center">
                <div className="bg-gray-100 md:w-56 p-4 rounded-2xl shadow-md">
                  <p className="font-bold">
                    Total Orders : {totalOrdersQuantities.totalOrders}
                  </p>
                </div>
                <div className="bg-gray-100 md:w-56 p-4 rounded-2xl shadow-md">
                  <p className="font-bold">
                    Total Quantity : {totalOrdersQuantities.totalQuantity}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-wrap lg:flex-nowrap justify-center">
              <div className="flex flex-wrap justify-evenly gap-4 items-center">
                <div className="bg-gray-100 md:w-56 p-4 rounded-2xl shadow-md">
                  <p className="font-bold">Total Orders : NA</p>
                </div>
                <div className="bg-gray-100 md:w-56 p-4 rounded-2xl shadow-md">
                  <p className="font-bold">Total Quantity : NA</p>
                </div>
              </div>
            </div>
          )}

          <div>
            <TabbedView />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
