import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Cookies from 'js-cookie'; 
import { toast } from 'react-toastify';
import ENDPOINTS from '../../service/endpoint';
import axiosInstance from '../../service/axiosInstance'; 
import { BeatLoader } from "react-spinners";

const AddLI = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    name: "",
    email: "",
    number: "",
    line: "",
    username: "",
  });

  const [assemblyLines, setAssemblyLines] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    const fetchAssemblyLines = async () => {
      try {
        const response = await axiosInstance.get(ENDPOINTS.ASSEMBLY_LINE_LIST);        
        setAssemblyLines(response.data);
      } catch (error) {
        console.error("Error fetching assembly lines:", error);


      }
    };

    fetchAssemblyLines();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSelectChange = (selectedOption) => {
    setForm({ ...form, line: selectedOption ? selectedOption.label : "" });
  };

  const validate = () => {
    const errors = {};
    const numberPattern = /^[0-9]{10}$/;
    const usernamePattern = /^[a-zA-Z][a-zA-Z0-9]*$/;

    if (!form.name) errors.name = "Name is required";
    if (form.email && !/\S+@\S+\.\S+/.test(form.email))
      errors.email = "Invalid email format";
    if (!form.number) errors.number = "Phone Number is required";
    if (!numberPattern.test(form.number))
      errors.number = "Invalid phone number format";
    if (!form.line) errors.line = "Assembly Line number is required";
    if (!form.username) errors.username = "Username is required";
    if (!usernamePattern.test(form.username))
      errors.username =
        "Username must be alphanumeric, start with a letter, and contain no spaces or special characters";
    return errors;
  };

   const handleSubmit = async (e) => {
     e.preventDefault();
     setLoading(true); // Start loading

     const validationErrors = validate();
     if (Object.keys(validationErrors).length > 0) {
       setErrors(validationErrors);
       setLoading(false); // Stop loading if there are validation errors
       return;
     }

     try {
       // If registration is successful, create the Line Incharge record
       const liPayload = {
         name: form.name,
         email: form.email,
         number: form.number,
         line: form.line,
         username: form.username,
       };
       await axiosInstance.post(ENDPOINTS.LINE_INCHARGE_LIST, liPayload);

      // Get the current date in yyyy-mm-dd format
      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}-${String(
        currentDate.getMonth() + 1
      ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

       // Create payload for the assembly line API call
       const assemblyLinePayload = {
         piName: "Production Incharge",
         lineName: form.line,
         date: formattedDate,
         lineIncharge: form.name,
         product: "Product",
         status: "Active",
         lastUpdateDateByLi: formattedDate,
         verifyByPi: "Not verified",
       };

       await axiosInstance.post(ENDPOINTS.ASSEMBLY_LINE, assemblyLinePayload);
       toast.success("Line Incharge User Created and Registered Successfully!");
       navigate("/li");
     } catch (error) {
       console.error("Error:", error);
       alert(error.response.data.message);
     } finally {
       setLoading(false); // Stop loading once the request is completed
     }
   };

  return (
    <div>
      <div className="mx-3">
        <button
          type="button"
          onClick={() => {
            navigate("/li");
          }}
          className="ml-8 w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto hover:bg-gray-100"
        >
          <svg
            className="w-5 h-5 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>
          <span>Go back</span>
        </button>
      </div>

      <div className="px-40 py-10">
        <p className="text-3xl font-extrabold tracking-tight text-slate-900 mb-7">
          Add Line Incharge
        </p>
        <form
          onSubmit={handleSubmit}
          className="grid gap-6 mb-6 md:grid-cols-2"
        >
          <div>
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Name
            </label>
            <input
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
            {errors.name && <p className="text-red-500">{errors.name}</p>}
          </div>
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
            {errors.email && <p className="text-red-500">{errors.email}</p>}
          </div>
          <div>
            <label
              htmlFor="number"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Phone Number
            </label>
            <input
              type="text"
              name="number"
              value={form.number}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
            {errors.number && <p className="text-red-500">{errors.number}</p>}
          </div>

          <div>
            <label
              htmlFor="line"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Assembly Line Number
            </label>
            <Select
              options={assemblyLines.map((line) => ({
                value: line._id,
                label: line.lineName,
              }))}
              onChange={handleSelectChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
              placeholder="Select a line..."
              isSearchable
            />
            {errors.line && <p className="text-red-500">{errors.line}</p>}
          </div>

          <hr className="my-2 w-full col-span-2" />

          <p className="col-span-2 text-3xl font-extrabold tracking-tight text-slate-900">
            Login Details
          </p>
          <div>
            <label
              htmlFor="username"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Username
            </label>
            <input
              type="text"
              name="username"
              value={form.username}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
            {errors.username && (
              <p className="text-red-500">{errors.username}</p>
            )}
          </div>

          <div className="col-span-2">
            <button
              type="submit"
              className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900 focus:outline-none flex justify-center items-center"
              disabled={loading} // Disable button while loading
            >
              {loading ? <BeatLoader size={8} color={"#ffffff"} /> : "Create LI User"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddLI;
