import {useQuery, useMutation } from "react-query";
import axiosInstance from "../../service/axiosInstance";
import ENDPOINTS from "../../service/endpoint";

const fetchOrderList = async (params) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `${ENDPOINTS.CUSTOMER_ORDER_LIST}${params?.id}`,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};
const useOrderList = () => {
  return useMutation({
    
    queryKey: ["useOrderList"],
    mutationFn: (params) => fetchOrderList(params),
  });
};

const fetchCustomerProductNames = async () => {
  const { data } = await axiosInstance({
    method: "get",
    url: `${ENDPOINTS.CUSTOMER_PRODUCT_NAMES}`,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};
const useCustomerProductNames = () => {
  return useQuery("customerProductNames", fetchCustomerProductNames);
};


const addCustomerProductName = async (productName) => {
  const { data } = await axiosInstance({
    method: "post",
    url: `${ENDPOINTS.CUSTOMER_PRODUCT_NAMES}`,
    headers: { "Content-Type": "application/json" },
    data: { productName }
  });
  return data;
};
const useAddCustomerProductName = () => {
  return useMutation(addCustomerProductName);
};

const fetchCustomerOrders = async (customerId) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `${ENDPOINTS.CUSTOMER_ORDER_LIST}/${customerId}`,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};
const useCustomerOrders = (customerId) => {
  return useQuery(["customerOrders", customerId], () => fetchCustomerOrders(customerId));
};
 

export {
  useOrderList,
  useCustomerProductNames,
  useAddCustomerProductName,    
  useCustomerOrders
};
