import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CreateableSelectWithDelete from "../../components/CreateableSelectWithDelete";
import ENDPOINTS from "../../service/endpoint";
import { useFormik } from "formik";
import {
  useRawMaterialUpdate,
  useSingleRawMaterial,
} from "../hooks/rawmaterial";
import {
  rawMaterialValidationSchema,
  initialValuesRawMaterial,
} from "../../validation";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import Cookies from "js-cookie"; // Import js-cookie

const EditRawMaterial = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  // const token = localStorage.getItem("token");
  const token = Cookies.get("accessToken");

  const id = useParams();
  const [initialValue, setInitialValue] = useState(null);
  console.log("🚀 ~ EditRawMaterial ~ initialValue:", initialValue)
  const { data, isLoading, mutateAsync, isSuccess, isError, error } =
    useRawMaterialUpdate();
  const navigate = useNavigate();
  const {
    data: singleRawMaterialData,
    isLoading: singleproductionLoading,
    mutateAsync: singlemutateasync,
    isSuccess: singleRawMaterialSuccess,
    isError: singleRawMaterialError,
    error: singleRawMaterialerror,
  } = useSingleRawMaterial();

  useEffect(() => {
    singlemutateasync({
      id: id?.id,
    });

    if (singleRawMaterialError) {
      toast.error(singleRawMaterialerror.response.data.message);

      if (singleRawMaterialerror.response.data.status === 400) {
        navigate("/rawmaterial");
      }
    }
  }, [id]);

  useEffect(() => {
    if (singleRawMaterialSuccess) {
      setInitialValue(singleRawMaterialData?.data);
    }
  }, [singleRawMaterialData, singleRawMaterialSuccess]);

  const {
    resetForm,
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    validationSchema: rawMaterialValidationSchema,
    onSubmit: async (values) => {
      const rawMaterialFormatted = {
        rawMaterial: values.rawMaterial.map(
          ({ materialName, variantName, quantity }) => ({
            materialName,
            variantName,
            quantity,
          })
        ),
      };
      try {
        await mutateAsync({ id: id, rawMaterial: values });
      } catch (error) {
        console.error("Error during submission:", error);
      }
    },
  });
    console.log("🚀 ~ EditRawMaterial ~ values:", values)

  useEffect(() => {
    if (isSuccess) {
      const { status, message } = data;
      toast.success(message);

      if (status === 200) {
        resetForm();
        navigate("/rawmaterial");
      }
    }

    if (isError) {
      toast.error(error.response.data.message);
    }
  }, [isError, isSuccess, data, error, resetForm, navigate]);

  return (
    <div>
      <div className="mx-3 mt-7">
        <button
          type="button"
          onClick={() => {
            navigate("/rawmaterial");
          }}
          className="ml-8 mt-3 flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto"
        >
          <svg
            className="w-5 h-5 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>
          <span>Go back</span>
        </button>
      </div>

      <div className="px-40 py-10">
        <h2 className="text-3xl font-extrabold tracking-tight text-slate-900 mb-7">
          EDIT Raw Material
        </h2>
        <form onSubmit={handleSubmit}>
          {values?.rawMaterial?.map((material, index) => (
            <div className="col-span-2" key={index}>
              <div className="grid grid-cols-3 gap-2">
                <label className="mb-1">Material Name</label>
                <label className="mb-1">Colour</label>
                <label className="mb-1">Quantity</label>
              </div>{" "}
              <div className="grid gap-6 md:grid-cols-3">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <CreateableSelectWithDelete
                    getArticlesUrl={`${baseURL}${ENDPOINTS.MATERIAL_NAME_LIST}`}
                    deleteArticleUrl={`${baseURL}${ENDPOINTS.MATERIAL_NAME_DELETE}`}
                    addArticleUrl={`${baseURL}${ENDPOINTS.ADD_MATERIAL_NAME}`}
                    token={token}
                    keyProp="materialName"
                    // value={material.materialName}
                    value={{
                      label: material.materialName,
                      value: material.materialName,
                    }}
                    onChange={(option) =>
                      setFieldValue(
                        `rawMaterial[${index}].materialName`,
                        option?.label || ""
                      )
                    }
                  />
                  {touched.rawMaterial &&
                    touched.rawMaterial[index] &&
                    touched.rawMaterial[index].materialName &&
                    errors.rawMaterial &&
                    errors.rawMaterial[index] &&
                    errors.rawMaterial[index].materialName && (
                      <p className="text-red-500">
                        {errors.rawMaterial[index].materialName}
                      </p>
                    )}
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <CreateableSelectWithDelete
                    getArticlesUrl={`${baseURL}${ENDPOINTS.VARIANT_NAME_LIST}`}
                    deleteArticleUrl={`${baseURL}${ENDPOINTS.VARIANT_NAME_DELETE}`}
                    addArticleUrl={`${baseURL}${ENDPOINTS.ADD_VARIANT_NAME}`}
                    token={token}
                    keyProp="variantName"
                    //   value={material.variantName}
                    value={{
                      label: material.variantName,
                      value: material.variantName,
                    }}
                    onChange={(option) =>
                      setFieldValue(
                        `rawMaterial[${index}].variantName`,
                        option?.label || ""
                      )
                    }
                  />

                  {touched.rawMaterial &&
                    touched.rawMaterial[index] &&
                    touched.rawMaterial[index].variantName &&
                    errors.rawMaterial &&
                    errors.rawMaterial[index] &&
                    errors.rawMaterial[index].variantName && (
                      <p className="text-red-500">
                        {errors.rawMaterial[index].variantName}
                      </p>
                    )}
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <input
                    className="col-start-2 col-end-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                    type="text"
                    value={material.quantity}
                    onChange={(e) =>
                      setFieldValue(
                        `rawMaterial[${index}].quantity`,
                        e.target.value
                      )
                    }
                    placeholder="Quantity"
                  />
                  {touched.rawMaterial &&
                    touched.rawMaterial[index] &&
                    touched.rawMaterial[index].quantity &&
                    errors.rawMaterial &&
                    errors.rawMaterial[index] &&
                    errors.rawMaterial[index].quantity && (
                      <p className="text-red-500">
                        {errors.rawMaterial[index].quantity}
                      </p>
                    )}
                </div>
              </div>
            </div>
          ))}
          <div className="flex justify-center">
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto mt-5 px-5 py-2.5 text-center"
              type="submit"
            >
              {isLoading ? (
                <BeatLoader size={8} color={"#ffffff"} />
              ) : (
                "Update Raw Material"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditRawMaterial;
