import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'; // Import PropTypes
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useAssemblyLine } from './hooks/assemblyline';
import { useEmployeeList } from './hooks/login';
import { ClipLoader } from 'react-spinners';




const baseURL = process.env.REACT_APP_BASE_URL

const AssemblyLine = ({ stationNumber }) => {
    const navigate = useNavigate()
    const {data, error, isLoading} = useAssemblyLine();

    const [assemblyLineData, setAssemblyLineData] = useState([])
    console.log("🚀 ~ AssemblyLine ~ assemblyLineData:", assemblyLineData)

    useEffect(() => {
        if (error) {
            navigate('/') // Replace '/error-page' with your desired error route
        } else {
            setAssemblyLineData(data?.data)
        }


     
    }, [data, error, navigate ])
   
    const handleAssemblyLine =(assembly)=>{
        navigate(`/line/asemblylinestation/${assembly._id}`, { state:assembly });
    }

    const checkSetupStatus = (date) => {
      const currentDate = new Date().toISOString().split('T')[0];
      const setupDate = new Date(date).toISOString().split('T')[0];
      console.log("🚀 ~ checkSetupStatus ~ setupDate:", setupDate)
      return currentDate === setupDate ? "Setup" : "Not Setup";
    };
    

  return (
   <div  className="m-9">
    <h2 style={{textAlign:'left', fontSize:'35px', fontWeight:'700', marginBottom:'15px'}}>Assembly Line</h2>


    {isLoading ?(
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'80vh'}}>

            <ClipLoader size={100}/>
        </div>
    ):(
    
        <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" >
            Assembly Line No.
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            LI Name
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              PI Name
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Setup By LI
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            verify By PI
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {assemblyLineData?.map((assembly, index) => (
            <tr key={index} data-tooltip-id={`tooltip-${index}`} onClick={()=>{handleAssemblyLine(assembly)}}  style={{ background: index % 2 === 0 ? "#fafafa" : "#8fa6ea"  , cursor:'pointer'}}>
              <td className="px-6 py-3 whitespace-nowrap"
              >{assembly?.lineName}</td>
              <td className="px-6 py-3 whitespace-nowrap"
               >{assembly?.lineIncharge}</td>
              <td className="px-6 py-3 whitespace-nowrap"
              >{assembly?.piName}</td>
              <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
              {checkSetupStatus(assembly?.lastUpdateDateByLi)}              </td>
              <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                {assembly?.verifyByPi}
              </td>
             
            </tr>
          ))}
        </tbody>
      </table>
   
 )} 
   </div> 
  )
}


// PropTypes validation for stationNumber prop
AssemblyLine.propTypes = {
    stationNumber: PropTypes.number.isRequired,
  };
  
export default AssemblyLine