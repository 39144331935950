import React from "react";
import PropTypes from "prop-types"; // Import PropTypes

import ReactApexChart from "react-apexcharts";
// import {data} from '../data/dummy'

const CurrentOrderGraph = ({ data }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    const ISTOffset = 330; // IST is UTC+5:30
    const ISTTime = new Date(date.getTime() + (ISTOffset + offset) * 60000);
    const day = String(ISTTime.getDate()).padStart(2, "0");
    const month = String(ISTTime.getMonth() + 1).padStart(2, "0");
    const year = ISTTime.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false, // Remove toolbar for a cleaner look
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Production",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 1,
      },
    },
    xaxis: {
      categories: data?.map((item, index) => formatDate(item?.date)),
      labels: {
        rotate: -80, // Rotate x-axis labels for better readability
        hideOverlappingLabels: true, // Automatically hide overlapping labels
      },
      tickAmount: 10,

      tickPlacement: "on",
      labels: {
        show: true, // Show x-axis labels dynamically
      },
    },

    yaxis: {
      min: 0, // Start y-axis at 0
      labels: {
        show: true, // Show y-axis labels
        formatter: (value) => Math.floor(value), // Format y-axis labels as integers
      },
    },
  };

  const series = [
    {
      name: "Quantity",
      data: data?.map((item) => item?.totalProduction), // Extracting data points from data
    },
  ];

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
        padding={10}
      />
    </div>
  );
};

CurrentOrderGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      totalProduction: PropTypes.number.isRequired,
    })
  ).isRequired,
};
export default CurrentOrderGraph;
