import React from "react";
import {
  AiOutlineCalendar,
  AiOutlineShoppingCart,
  AiOutlineShopping,
  AiOutlineShop,
  AiOutlineAreaChart,
  AiOutlineBarChart,
  AiOutlineStock,
} from "react-icons/ai";
import { MdOutlineWarehouse } from "react-icons/md";

import {
  FiShoppingBag,
  FiEdit,
  FiPieChart,
  FiBarChart,
  FiCreditCard,
  FiStar,
  FiShoppingCart,
} from "react-icons/fi";
import {
  BsKanban,
  BsBarChart,
  BsBoxSeam,
  BsCurrencyDollar,
  BsShield,
  BsChatLeft,
} from "react-icons/bs";
import { IoMdContacts, IoMdContact } from "react-icons/io";
import {
  RiContactsLine,
  RiStockLine,
  RiContactsBookFill,
  RiContactsBook2Fill,
  RiContactsBookLine,
  RiContactsBook2Line,
  RiContactsBookUploadLine,
  RiContactsFill,
} from "react-icons/ri";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import { RiAiGenerate } from "react-icons/ri";

export const data = [
  {
    date: "2024-05-01",
    totalProduction: 20,
  },
  {
    date: "2024-05-02",
    totalProduction: 25,
  },
  {
    date: "2024-05-03",
    totalProduction: 50,
  },
  {
    date: "2024-05-04",
    totalProduction: 60,
  },
  {
    date: "2024-05-05",
    totalProduction: 40,
  },
  {
    date: "2024-05-06",
    totalProduction: 50,
  },
  {
    date: "2024-05-07",
    totalProduction: 40,
  },
  {
    date: "2024-05-08",
    totalProduction: 50,
  },
];

export const gridOrderImage = (props) => (
  <div>
    <img
      className="rounded-xl h-20 md:ml-3"
      src={props.ProductImage}
      alt="order-item"
    />
  </div>
);

export const gridOrderStatus = (props) => (
  <button
    type="button"
    style={{ background: props.StatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.Status}
  </button>
);

export const kanbanGrid = [
  { headerText: "To Do", keyField: "Open", allowToggle: true },

  { headerText: "In Progress", keyField: "InProgress", allowToggle: true },

  {
    headerText: "Testing",
    keyField: "Testing",
    allowToggle: true,
    isExpanded: false,
  },

  { headerText: "Done", keyField: "Close", allowToggle: true },
];
const gridEmployeeProfile = (props) => (
  <div className="flex items-center gap-2">
    <img
      className="rounded-full w-10 h-10"
      src={props.EmployeeImage}
      alt="employee"
    />
    <p>{props.Name}</p>
  </div>
);

const gridEmployeeCountry = (props) => (
  <div className="flex items-center justify-center gap-2">
    <GrLocation />
    <span>{props.Country}</span>
  </div>
);
const customerGridImage = (props) => (
  <div className="image flex gap-4">
    <img
      className="rounded-full w-10 h-10"
      src={props.CustomerImage}
      alt="employee"
    />
    <div>
      <p>{props.CustomerName}</p>
      <p>{props.CustomerEmail}</p>
    </div>
  </div>
);

const customerGridStatus = (props) => (
  <div className="flex gap-2 justify-center items-center text-gray-700 capitalize">
    <p
      style={{ background: props.StatusBg }}
      className="rounded-full h-3 w-3"
    />
    <p>{props.Status}</p>
  </div>
);

export const LinePrimaryXAxis = {
  valueType: "DateTime",
  labelFormat: "y",
  intervalType: "Years",
  edgeLabelPlacement: "Shift",
  majorGridLines: { width: 0 },
  background: "white",
};

export const LinePrimaryYAxis = {
  labelFormat: "{value}%",
  rangePadding: "None",
  minimum: 0,
  maximum: 100,
  interval: 20,
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const customersGrid = [
  { field: "Sno", headerText: "S.No", width: "50", textAlign: "Center" },
  {
    field: "CustomerName",
    headerText: "Customer Name",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "products",
    headerText: "Products",
    width: "100",
    format: "C2",
    textAlign: "Center",
  },
  {
    field: "poc",
    headerText: "Point of Contact",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "number",
    headerText: "Phone Number",
    width: "120",
    textAlign: "Center",
    isPrimaryKey: true,
  },
  { type: "checkbox", width: "50" },
];

export const piGrid = [
  { field: "Sno", headerText: "S.No", width: "50", textAlign: "Center" },
  {
    field: "piName",
    headerText: "Production Incharge Name",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "lines",
    headerText: "No. of Lines",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "contact",
    headerText: "Contact",
    width: "150",
    textAlign: "Center",
  },
  { type: "checkbox", width: "50" },
];

export const liGrid = [
  { field: "Sno", headerText: "S.No", width: "50", textAlign: "Center" },
  {
    field: "liName",
    headerText: "Line Incharge Name",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "line",
    headerText: "Assembly Line",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "contact",
    headerText: "Contact",
    width: "150",
    textAlign: "Center",
  },
];

export const productMasterGrid = [
  // { field: 'Sno',
  //   headerText: 'S.No.',
  //   width: '50',
  //   textAlign: 'Center'
  // },
  {
    field: "productName",
    headerText: "Product",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "quantity",
    headerText: "Raw Material for Quantity",
    width: "100",
    format: "C2",
    textAlign: "Center",
  },
  { type: "checkbox", width: "50" },
];

export const employeesGrid = [
  { field: "Sno", headerText: "S.No.", width: "50", textAlign: "Center" },
  {
    headerText: "Employee Name",
    width: "150",
    template: gridEmployeeProfile,
    textAlign: "Center",
  },
  {
    field: "Name",
    headerText: "Employee Name",
    width: "0",
    textAlign: "Center",
  },
  {
    field: "Line",
    headerText: "Assembly Line",
    width: "170",
    textAlign: "Center",
  },
  {
    field: "Station",
    headerText: "Station",
    width: "120",
    textAlign: "Center",
  },
  {
    field: "Designation",
    headerText: "Designation",
    width: "135",
    format: "yMd",
    textAlign: "Center",
  },
];

export const links = [
  {
    title: " Admin Dashboard",
    links: [
      {
        name: "Dashboard",
        url: "dashboard",
        icon: <AiOutlineShop />,
      },
      {
        name: "Orders",
        url: "orders",
        icon: <AiOutlineShopping />,
      },
      {
        name: "Production Plan",
        url: "plan",
        icon: <FiShoppingCart />,
      },
      {
        name: "Customers",
        url: "customers",
        icon: <RiContactsBookLine />,
      },
      {
        name: "Product Master",
        url: "product",
        icon: <RiAiGenerate />,
      },
      {
        name: "Production Incharge",
        url: "pi",
        icon: <RiContactsFill />,
      },
      {
        name: "Line Incharge",
        url: "li",
        icon: <RiContactsLine />,
      },
      {
        name: "Line Workers",
        url: "lineworkers",
        icon: <IoMdContacts />,
      },
      {
        name: "Warehouse",
        url: "warehouse",
        icon: <MdOutlineWarehouse />,
      },
      // {
      //   name: "Qualtiy Reports",
      //   url: "reports",
      //   icon: <BsKanban />,
      // },
      {
        name: "Assembly Line",
        url: "line",
        icon: <BsKanban />,
      },
      {
        name: "SKU",
        url: "rawmaterial",
        icon: <BsKanban />,
      },
    ],
  },
];

export const QualtiyReportsData = [
  {
    title1: "Product Name",
    title2: "Customer Name",
    title3: "Order Date",
    title4: "Delivery Date",

    value1: "TWS",
    value2: "John Doe",
    value3: "12/02/24",
    value4: "15/09/24",
  },
  {
    title1: "Product Name",
    title2: "Customer Name",
    title3: "Order Date",
    title4: "Delivery Date",

    value1: "Neckband",
    value2: "John Doe",
    value3: "12/02/24",
    value4: "15/09/24",
  },
  {
    title1: "Product Name",
    title2: "Customer Name",
    title3: "Order Date",
    title4: "Delivery Date",

    value1: "Charger",
    value2: "John Doe",
    value3: "12/02/24",
    value4: "15/09/24",
  },
];

export const themeColors = [
  {
    name: "blue-theme",
    color: "#1A97F5",
  },
  {
    name: "green-theme",
    color: "#03C9D7",
  },
  {
    name: "purple-theme",
    color: "#7352FF",
  },
  {
    name: "red-theme",
    color: "#FF5C8E",
  },
  {
    name: "indigo-theme",
    color: "#1E4DB7",
  },
  {
    color: "#FB9678",
    name: "orange-theme",
  },
];

export const userProfileData = [
  {
    icon: <BsCurrencyDollar />,
    title: "My Profile",
    desc: "Account Settings",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
  },
  {
    icon: <BsShield />,
    title: "My Inbox",
    desc: "Messages & Emails",
    iconColor: "rgb(0, 194, 146)",
    iconBg: "rgb(235, 250, 242)",
  },
  {
    icon: <FiCreditCard />,
    title: "My Tasks",
    desc: "To-do and Daily Tasks",
    iconColor: "rgb(255, 244, 229)",
    iconBg: "rgb(254, 201, 15)",
  },
];

export const warehouseRawMaterialsGrid = [
  {
    field: "Sno",
    headerText: "S.No",
    width: "50",
    textAlign: "Center",
  },
  {
    field: "Line",
    headerText: "Assembly Line",
    width: "150",
    editType: "dropdownedit",
    textAlign: "Center",
  },
  {
    field: "Material1",
    headerText: "Material 1",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "Material2",
    headerText: "Material 2",
    width: "120",
    textAlign: "Center",
  },
  {
    field: "Material3",
    headerText: "Material 3",
    width: "120",
    textAlign: "Center",
  },
  {
    field: "Status",
    headerText: "Status at Warehouse",
    width: "120",
    textAlign: "Center",
  },
];

export const orderRawMaterialsGrid = [
  {
    field: "Sno",
    headerText: "S.No",
    width: "50",
    textAlign: "Center",
  },
  {
    field: "Material1",
    headerText: "Material 1",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "Material2",
    headerText: "Material 2",
    width: "120",
    textAlign: "Center",
  },
  {
    field: "Material3",
    headerText: "Material 3",
    width: "120",
    textAlign: "Center",
  },
];

export const productionOutputGrid = [
  {
    field: "Sno",
    headerText: "S.No",
    width: "50",
    textAlign: "Center",
  },
  {
    field: "Line",
    headerText: "Assembly Line",
    width: "150",
    editType: "dropdownedit",
    textAlign: "Center",
  },
  {
    field: "Product",
    headerText: "Product",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "Units",
    headerText: "Units",
    width: "120",
    textAlign: "Center",
  },
  {
    field: "Status",
    headerText: "Status at Warehouse",
    width: "120",
    textAlign: "Center",
  },
];

export const customersData = [
  {
    Sno: "1",
    CustomerID: 1001,
    CustomerName: "Nirav Joshi",
    CustomerEmail: "nirav@gmail.com",
    products: "40",
    poc: "Sandeep Kumar",
    number: "+91 xxxxxxxxxx",
  },
  {
    Sno: "2",
    CustomerID: 1039,
    CustomerName: "Christopher Jamil",
    CustomerEmail: "jamil@gmail.com",
    products: "34",
    poc: "Deepak Kumar",
    number: "+91 xxxxxxxxxx",
  },
];

export const piData = [
  {
    Sno: "1",
    piName: "Dane Joe",
    lines: "6",
    contact: "+91 xxxxxxxxxx",
  },
  {
    Sno: "2",
    piName: "Jane Doe",
    lines: "3",
    contact: "+91 xxxxxxxxxx",
  },
];

export const liData = [
  {
    Sno: "1",
    liName: "Dane Joe",
    line: "Line 2",
    contact: "+91 xxxxxxxxxx",
  },
  {
    Sno: "2",
    liName: "Jane Doe",
    line: "Line 3",
    contact: "+91 xxxxxxxxxx",
  },
];

export const employeesData = [
  {
    Sno: 1,
    Name: "Aditya Kumar",
    Line: "Line 1",
    Station: "Station 3",
    Designation: "Packaging",
  },
  {
    Sno: 2,
    Name: "Pintu Kumar",
    Line: "Line 2",
    Station: "Station 1",
    Designation: "Packaging",
  },
  {
    Sno: 3,
    Name: "Vipul Wasnik",
    Line: "Line 3",
    Station: "Station 4",
    Designation: "Packaging",
  },
  {
    Sno: 4,
    Name: "Ojus Kumar",
    Line: "Line 3",
    Station: "Station 6",
    Designation: "Packaging",
  },
  {
    Sno: 5,
    Name: "Aditya Kumar",
    Line: "Line 1",
    Station: "Station 3",
    Designation: "Packaging",
  },
  {
    Sno: 6,
    Name: "Aditya Kumar",
    Line: "Line 1",
    Station: "Station 3",
    Designation: "Packaging",
  },
  {
    Sno: 7,
    Name: "Aditya Kumar",
    Line: "Line 1",
    Station: "Station 3",
    Designation: "Packaging",
  },

  {
    Sno: 8,
    Name: "Aditya Kumar",
    Line: "Line 1",
    Station: "Station 3",
    Designation: "Packaging",
  },
];

export const warehouseRawMaterialsData = [
  {
    Sno: 1,
    Line: "Line 1",
    Material1: "Material 1",
    Material2: "Material 2",
    Material3: "Material 3",
    Status: "Allocated",
  },
  {
    Sno: 2,
    Line: "Line 2",
    Material1: "Material 1",
    Material2: "Material 2",
    Material3: "Material 3",
    Status: "Not Allocated",
  },
];

export const productionOutputData = [
  {
    Sno: 1,
    Line: "Line 1",
    Product: "Neckband",
    Units: "1200",
    Status: "Received",
  },
  {
    Sno: 2,
    Line: "Line 2",
    Product: "TWS",
    Units: "2000",
    Status: "Not Received",
  },
];

export const orderDetailsData = [
  {
    title: "OrderId",
    amount: "113",
  },
  {
    title: "Product Completed",
    amount: "2333",
  },
  {
    title: "Start Date of Production",
    amount: "12/01/24",
  },
  {
    title: "Expected Date of Completion",
    amount: "13/04/24",
  },
  {
    title: "Quantity that can be Produced with available Raw Materials",
    amount: "123",
  },
];

export const orderRawMaterialsData = [
  {
    Sno: "1",
    Material1: "Material 1",
    Material2: "Material 2",
    Material3: "Material 3",
  },
  {
    Sno: "2",
    Material1: "Material 1",
    Material2: "Material 2",
    Material3: "Material 3",
  },
  {
    Sno: "3",
    Material1: "Material 1",
    Material2: "Material 2",
    Material3: "Material 3",
  },
];

export const scheduleData = [
  {
    Id: 1,
    Subject: "Explosion of Betelgeuse Star",
    StartTime: "2021-01-10T04:00:00.000Z",
    EndTime: "2021-01-10T05:30:00.000Z",
    CategoryColor: "#1aaa55",
  },
  {
    Id: 2,
    Subject: "Charger-100",
    StartTime: "2024-02-14T00:01.000Z",
    EndTime: "2024-02-14T11:59.000Z",
    CategoryColor: "#1aaa55",
  },
];

export const lineChartData = [
  [
    { x: new Date(2005, 0, 1), y: 21 },
    { x: new Date(2006, 0, 1), y: 24 },
    { x: new Date(2007, 0, 1), y: 36 },
    { x: new Date(2008, 0, 1), y: 38 },
    { x: new Date(2009, 0, 1), y: 54 },
    { x: new Date(2010, 0, 1), y: 57 },
    { x: new Date(2011, 0, 1), y: 70 },
  ],
  [
    { x: new Date(2005, 0, 1), y: 28 },
    { x: new Date(2006, 0, 1), y: 44 },
    { x: new Date(2007, 0, 1), y: 48 },
    { x: new Date(2008, 0, 1), y: 50 },
    { x: new Date(2009, 0, 1), y: 66 },
    { x: new Date(2010, 0, 1), y: 78 },
    { x: new Date(2011, 0, 1), y: 84 },
  ],

  [
    { x: new Date(2005, 0, 1), y: 10 },
    { x: new Date(2006, 0, 1), y: 20 },
    { x: new Date(2007, 0, 1), y: 30 },
    { x: new Date(2008, 0, 1), y: 39 },
    { x: new Date(2009, 0, 1), y: 50 },
    { x: new Date(2010, 0, 1), y: 70 },
    { x: new Date(2011, 0, 1), y: 100 },
  ],
];
export const dropdownData = [
  {
    Id: "1",
    Time: "March 2021",
  },
  {
    Id: "2",
    Time: "April 2021",
  },
  {
    Id: "3",
    Time: "May 2021",
  },
];

export const lineCustomSeries = [
  {
    dataSource: lineChartData[0],
    xName: "x",
    yName: "y",
    name: "Germany",
    width: "2",
    marker: { visible: true, width: 10, height: 10 },
    type: "Line",
  },

  {
    dataSource: lineChartData[1],
    xName: "x",
    yName: "y",
    name: "England",
    width: "2",
    marker: { visible: true, width: 10, height: 10 },
    type: "Line",
  },

  {
    dataSource: lineChartData[2],
    xName: "x",
    yName: "y",
    name: "India",
    width: "2",
    marker: { visible: true, width: 10, height: 10 },
    type: "Line",
  },
];

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];

export const kanbanData = [
  {
    Id: "Task 14",
    Title: "Task - 29019",
    Status: "Validate",
    Summary: "Validate databinding issues.",
    Type: "Story",
    Priority: "Low",
    Tags: "Validation",
    Estimate: 1.5,
    Assignee: "Margaret hamilt",
    RankId: 1,
    Color: "#02897B",
    ClassName: "e-story, e-low, e-margaret-hamilt",
  },
  {
    Id: "Task 15",
    Title: "Task - 29020",
    Status: "Close",
    Summary: "Analyze grid control.",
    Type: "Story",
    Priority: "High",
    Tags: "Analyze",
    Estimate: 2.5,
    Assignee: "Margaret hamilt",
    RankId: 5,
    Color: "#02897B",
    ClassName: "e-story, e-high, e-margaret-hamilt",
  },
  {
    Id: "Task 16",
    Title: "Task - 29021",
    Status: "Close",
    Summary: "Stored procedure for initial data binding of the grid.",
    Type: "Others",
    Priority: "Critical",
    Tags: "Databinding",
    Estimate: 1.5,
    Assignee: "Steven walker",
    RankId: 6,
    Color: "#1F88E5",
    ClassName: "e-others, e-release, e-steven-walker",
  },
  {
    Id: "Task 17",
    Title: "Task - 29022",
    Status: "Close",
    Summary: "Analyze stored procedures.",
    Type: "Story",
    Priority: "Critical",
    Tags: "Procedures",
    Estimate: 5.5,
    Assignee: "Janet Leverling",
    RankId: 7,
    Color: "#02897B",
    ClassName: "e-story, e-release, e-janet-leverling",
  },
  {
    Id: "Task 18",
    Title: "Task - 29023",
    Status: "Validate",
    Summary: "Validate editing issues.",
    Type: "Story",
    Priority: "Critical",
    Tags: "Editing",
    Estimate: 1,
    Assignee: "Nancy Davloio",
    RankId: 1,
    Color: "#02897B",
    ClassName: "e-story, e-critical, e-nancy-davloio",
  },
];

