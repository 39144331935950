import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {children}
    </ErrorContext.Provider>
  );
};

ErrorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useError = () => {
  return useContext(ErrorContext);
};
