import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CurrentOrderGraph from './CurrentOrderGraph';
import {data} from '../data/dummy'
import { useGraphDetails } from '../pages/hooks/graph';
import { ClipLoader } from 'react-spinners';
import moment from "moment";
import Cookies from 'js-cookie'; // Import js-cookie
import ENDPOINTS from "../service/endpoint";
import axiosInstance from '../service/axiosInstance'; // Import the axiosInstance

const TabbedView = () => {
  const {data,mutate, isLoading}=useGraphDetails()
  const navigate = useNavigate();
  const tabContainerRef = useRef(null);
  
  const [currentOrders, setCurrent]=useState([]);
  const [activeTab, setActiveTab] = useState(currentOrders && currentOrders[0]);
  const [failRate, setFailRate]= useState();
  const [graphData, setGraphData] = useState([])
  
  useEffect(() => {
    if (currentOrders.length > 0) {
      setActiveTab(currentOrders[0]);
    }
  }, [currentOrders]);
  
  useEffect(() => {
    if (activeTab) {
      // Fetch new graph data based on activeTab if necessary
      mutate({ id: activeTab._id });
    }
  }, [activeTab, mutate]);

  useEffect(() => {
    setGraphData(data?.data)
    
  }, [data])

  const fetchCurrentOrders = async () => {
    try {
      const response = await axiosInstance.get(ENDPOINTS.CURRENT_ORDERS);
      await setCurrent(response?.data?.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const fetchFailRates = async () => {
    try {
      const response = await axiosInstance.get(`${ENDPOINTS.AVERAGE_FAIL_RATE}${activeTab._id}`);
      setFailRate(response?.data?.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  useEffect(() => {
    fetchCurrentOrders();
  }, []);

  useEffect(() => {
    if (activeTab){
    fetchFailRates();
    }
  }, [activeTab]);


  const handleTabClick = (order) => {
    setActiveTab(order);
  };

  const scrollLeft = () => {
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollLeft -= 200; // Adjust scroll amount as needed
    }
  };

  const scrollRight = () => {
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollLeft += 200; // Adjust scroll amount as needed
    }
  };

  const convertDateFormat = (dateStr) => {
    const [day, month, year] = dateStr?.split('-');
    return `${year}-${month}-${day}`;
  };


  const calculateWeekdaysFromStart = (startDate) => {
    const start = moment(startDate).endOf('day'); // Use endOf('day') to count at the end of the day
    const end = moment().endOf('day'); // Use endOf('day') to count at the end of the day
    let weekdays = 0;

    while (start.isSameOrBefore(end)) {
      if (start.isoWeekday() !== 7) { // Exclude Sundays (7 is Sunday in ISO week date)
        weekdays++;
      }
    start.add(1, 'day');
    }

    return weekdays;
  };
  const calculateCurrentAvgDailyProd = (shippedQuantity, actualStartDateStr) => {
    const actualStartDate = actualStartDateStr && (convertDateFormat(actualStartDateStr));
    const daysFromStart = calculateWeekdaysFromStart(actualStartDate);
    return daysFromStart > 0 ? (shippedQuantity / daysFromStart).toFixed(2) : 0;
  };

  const currentAvgDailyProd = calculateCurrentAvgDailyProd(
    failRate?.totalOutput,
    activeTab?.actualStartDate 
   );


  return (
    <div className="max-w-5xl mx-auto mt-8 p-4 bg-gray-100 rounded shadow relative">
      <h2 className="text-2xl font-bold mb-4">Orders in Production</h2>

      {/* Arrow Buttons */}
      {/* <button className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2" onClick={scrollLeft}>
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
        </svg>
      </button> */}

      <div className="flex items-center space-x-4 overflow-x-auto" ref={tabContainerRef}>
        {Object(currentOrders)?.map((order, index) => (
          <button
            key={index}
            className={`p-3 m-2 rounded-lg bg-gray-200 text-gray-700 font-bold hover:bg-sky-500 hover:text-slate-50 hover:bg-opacity-80 ${
              activeTab === order ? 'bg-sky-600 text-slate-50' : 'text-gray-800'
            } rounded`} 
            onClick={() => handleTabClick(order)}
          >
            {order?.customerProductName+" : "+order?.factoryProductName}
          </button>
        ))}
      </div>

      {/* <button className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2" onClick={scrollRight}>
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
        </svg>
      </button> */}

      
      <div className="flex">
        <div className="p-2 w-2/5">
          <p className='font-bold pb-3'>Current Order Details: </p>
          {failRate ? (
            <div>
              <p className="font-bold bg-gray-300 rounded-lg p-2 m-2">QC Fail Rate : {failRate?.qcFailRate}%</p>
              <p className="font-bold bg-gray-300 rounded-lg p-2 m-2">Conversion Fail Rate : {failRate?.conversionFailRate}%</p>
              <p className="font-bold bg-gray-300 rounded-lg p-2 m-2">Current Avg. Daily Production : {currentAvgDailyProd === 0 ? 'not started' : currentAvgDailyProd}</p>

            </div>
          ):
            <div>
              <p className="font-bold bg-gray-300 rounded-lg p-2 m-2">QC Fail Rate : NA</p>
              <p className="font-bold bg-gray-300 rounded-lg p-2 m-2">Conversion Fail Rate : NA</p>
              <p className="font-bold bg-gray-300 rounded-lg p-2 m-2">Current Avg. Daily Production : NA</p>

            </div>
          }
          <hr className='my-3'></hr>
          {activeTab? (
            <div>
              <p className="font-bold bg-gray-300 rounded-lg p-2 m-2">Actual Start Date : {activeTab?.actualStartDate}</p>
              <p className="font-bold bg-gray-300 rounded-lg p-2 m-2">Promised Start Date : {activeTab?.promisedStartDate}</p>              
              <p className="font-bold bg-gray-300 rounded-lg p-2 m-2">Promised End Date: {activeTab?.deadline}</p>
            </div>
          ):(
            <div>
              <p className="font-bold bg-gray-300 rounded-lg p-2 m-2">Actual Start Date : NA</p>
              <p className="font-bold bg-gray-300 rounded-lg p-2 m-2">Promised Start Date : NA</p>              
              <p className="font-bold bg-gray-300 rounded-lg p-2 m-2">Promised End Date: NA</p>
            </div>
          )}
        </div>
          {isLoading ?(
            <div className='w-3/5 mt-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ClipLoader size={100} />
          </div>
          ):(
            graphData?.length>0 ?(
        <div className='w-3/5 mt-2'>
          <CurrentOrderGraph data={graphData}/>
        </div>):(
          <div className='w-3/5 mt-2' style={{display:'flex',justifyContent:'center', alignItems:'center'}} >
          <p style={{fontSize:'20px', fontWeight:'700'}}>NO Data</p>
          </div>
        )
      )}
      </div>

      <div>
        
      </div>
      
      {/* <button onClick={() => navigate('/details')} className="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">More Details</button> */}
    </div>
  );
};

export default TabbedView;
