import {useQuery, useMutation } from "react-query";
import axiosInstance from "../../service/axiosInstance";
import ENDPOINTS from "../../service/endpoint";


const graphDetails = async (params) => {
    const { data } = await axiosInstance({
      method: "get",
      url: `${ENDPOINTS.PRODUCTION_RATE_GRAPH}/${params.id}`,
      headers: { "Content-Type": "application/json" },
    });
    return data;
  };
  
  const useGraphDetails = () => {
    return useMutation({
      
      queryKey: ["useGraphDetails"],
      mutationFn: (params) => graphDetails(params),
    });
  };

  export { useGraphDetails };
