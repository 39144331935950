import * as Yup from "yup";

// initial values
const initialValuesProductionIncharge = {
  name: "",
  selectLI: [],
  email: "",
  phone: "",
  username: ""
};

const initialValuesProductionPlan = {
  customerId: "",
  orderId: "",
  assignAssemblyLineId: "",
  averageDailyProduction: "",
  startDate: "",
  projectedEndDate: "",
};

const initialValuesRawMaterial = {
  rawMaterial: [{ materialName: "", variantName: "", quantity: "" }],
};
// validation schema
const validationSchemaProductionIncharge = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  selectLI: Yup.array()
    .of(
      Yup.object().shape({
        liID: Yup.string().required("LIId name is required"),
        // LINO: Yup.string().required("Line number is required"),
      })
    )
    .min(1, "At least one LI is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Invalid phone number format")
    .required("Phone Number is required"),
  username: Yup.string()
    .matches(
      /^[a-zA-Z][a-zA-Z0-9]*$/,
      "Username must be alphanumeric, start with a letter, and contain no spaces or special characters"
    )
    .required("Username is required"),
});

// production plan validation
const productionplanvalidationSchema = Yup.object().shape({
  customerId: Yup.string().required("Customer Name is required"),
  orderId: Yup.string().required("Order Name is required"),
  assignAssemblyLineId: Yup.string().required("Assembly Line is required"),
  averageDailyProduction: Yup.number()
    .required("Average Daily Production is required")
    .positive("Must be a positive number"),
  startDate: Yup.date()
    .required("Start Date is required")
    .test("not-sunday", "Start date cannot be a Sunday", (value) => {
      const day = new Date(value).getDay();
      return day !== 0; // 0 represents Sunday
    }),
  projectedEndDate: Yup.string().required("End Date is required"),
  // .min(
  //   Yup.ref("startDate"),
  //   "Projected End Date cannot be before Start Date"
  // ),
});

const rawMaterialValidationSchema = Yup.object().shape({
  rawMaterial: Yup.array()
    .of(
      Yup.object().shape({
        materialName: Yup.string().required("Material name is required"),
        variantName: Yup.string().required("Variant is required"),
        quantity: Yup.string().required("Quantity is required"),
      })
    )
    .required("Must have raw materials"), // Ensure the array itself is required
});
export {
  initialValuesProductionIncharge,
  validationSchemaProductionIncharge,
  productionplanvalidationSchema,
  initialValuesProductionPlan,
  initialValuesRawMaterial,
  rawMaterialValidationSchema,
};
