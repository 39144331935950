import React, { useEffect, useState } from 'react';
import MyCalendar from '../components/MyCalender';
import { useNavigate } from 'react-router-dom';
import { useProductionPlanList } from './hooks/productionPlan';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

const ProductionPlan = () => {
    const navigate = useNavigate();
    const { data, isLoading, mutate, isSuccess, isError, error } = useProductionPlanList();
    const [productionPlanList, setProductionPlanList] = useState([]);

    useEffect(() => {
      if (!isSuccess) {
        mutate();
      }

      if (isError) {
        toast.error(error.response.data.message);
        
      }
    }, [isSuccess, isError]);

    useEffect(() => {
      if (data) {
        setProductionPlanList(data?.data?.productionList);
      }
    }, [data]);

    const handleDeleteEvent = (id) => {
      setProductionPlanList((prev) => prev.filter((item) => item._id !== id));
    };

    return (
      <>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', width: '80vw' }}>
          <ClipLoader size={100} />
        </div>
      ) : (
      <div className='m-9'>
        <div className='piheader' style={{ display: 'flex', justifyContent: 'end', alignContent: 'center' }}>
          <button
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            onClick={() => navigate('/plan/addproductionplan')} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            Create Production Plan
          </button>
        </div>
       
          <MyCalendar events={productionPlanList} onDeleteEvent={handleDeleteEvent} />
      </div>
        )}
      </>
    );
}

export default ProductionPlan;
