import React from 'react'
import { QualtiyReportsData } from '../data/dummy';

const Reports = () => {
  return (
    <div className='mt-10'>
        <div className="flex flex-wrap lg:flex-nowrap justify-center ">
            <div className="flex flex-wrap justify-evenly gap-6 items-center">
                {QualtiyReportsData.map((item) => (
                <div key={item.title} className="bg-gray-100 p-4 rounded-2xl shadow-md">
                    <p className="font-bold  mt-3">{item.title1} : <span className='font-normal'>{item.value1}</span> </p>
                    <p className="font-bold  mt-3">{item.title2} : <span className='font-normal'>{item.value2}</span></p>
                    <p className="font-bold  mt-3">{item.title3} : <span className='font-normal'>{item.value3}</span></p>
                    <p className="font-bold  mt-3">{item.title4} : <span className='font-normal'>{item.value4}</span></p>

                    <button className="mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">View Report</button>
                </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default Reports;