import React, { useState } from 'react';
import { Header } from '../components';
import SkuDetails from './WareHouse/SkuDetails';
import ProductionDetails from './WareHouse/ProductionDetails';

const Warehouse = () => { 
  const [activeTab, setActiveTab] = useState('skudetails')
  const handleTabClick =(text)=>{
    setActiveTab(text)    

  }
  return (
    <div className='m-2 md:m-10 mt-24 p-2 bg-white rounded-3xl'>
       <div className="order mb-5">
      <button
            className={`p-3 m-2 rounded-lg bg-gray-200 text-gray-700 font-bold hover:bg-sky-500 hover:text-slate-50 hover:bg-opacity-80 ${
              activeTab === 'skudetails' ? 'bg-sky-600 text-slate-50' : 'text-gray-800'
            } rounded`} 
            onClick={() => handleTabClick('skudetails')}
          >
            Outbound
          </button>

          <button
            className={`p-3 m-2 rounded-lg bg-gray-200 text-gray-700 font-bold hover:bg-sky-500 hover:text-slate-50 hover:bg-opacity-80 ${
              activeTab === 'productiondetails'  ? 'bg-sky-600 text-slate-50' : 'text-gray-800'
            } rounded`} 
            onClick={() => handleTabClick('productiondetails' )}
          >
            Inbound
          </button>
      </div>

{ activeTab === 'skudetails' && (
<>
      <Header category="" title="Outbound Status" />
      <div className='mb-10'>
        <SkuDetails/>
      </div>
</>
)}

{ activeTab === 'productiondetails' && (
  <>
      <Header category="" title="Inbound Status" />
      <div className='mb-10'>
        <ProductionDetails/>
      </div>
      </>
  )}
    </div>
  );
};
export default Warehouse;
