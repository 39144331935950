import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader'; 
import Cookies from 'js-cookie'; 
import { toast } from 'react-toastify'
import ENDPOINTS from '../service/endpoint';
import axiosInstance from '../service/axiosInstance'; // Import the axiosInstance

const LineIncharge = () => {
  const navigate = useNavigate();
  const [lis, setLIs] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const fetchLIs = async () => {
    try {
      const response = await axiosInstance.get(ENDPOINTS.LINE_INCHARGE_LIST);
      setLIs(response?.data?.data);
    } catch (error) {
      console.error('Error fetching Line Incharges:', error);
    } finally {
      setLoading(false); // Set loading to false after data fetch is complete
    }
  };

  useEffect(() => {
    fetchLIs();
  }, []);


  const handleDelete = async (userId , liId, name) => {
    // Ask for confirmation before deleting
    const confirmed = window.confirm(`Are you sure you want to delete the Line Incharge: ${name}?`);
    if (!confirmed) {
      return; // If user cancels, do nothing
    }

    try {
      await axiosInstance.delete(`${ENDPOINTS.LINE_INCHARGE_LIST}${userId}/${liId}`);
      // Filter out the deleted order from the current state
      const updatedLIs = lis.filter((li) => li._id !== liId);
      setLIs(updatedLIs);
    } catch (error) {
      console.error('Error deleting Line Incharge:', error);
    
    }
  };

  return (
    <div className="mx-9 px-9 mt-2">
      <div className="flex justify-between mb-7">
            <p className="text-3xl font-extrabold tracking-tight text-slate-900">Line Incharge</p>
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2text-center"
              onClick={() => navigate('/li/addli')}
            >
              Create Line Incharge
            </button>
      </div>

      {loading ? (
        <Loader /> 
      ) : (
        <div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Line Incharge Name
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Assembly Line
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Phone Number
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Delete</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {lis.map((li, index) => (
                <tr key={li._id} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                  <td className="px-6 py-4 whitespace-nowrap">{li.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{li.line}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{li.number}</td>         
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button className="text-blue-600 hover:text-blue-900" onClick={() => navigate(`/li/editli/${li.userId}/${li._id}`)}>
                      Edit
                    </button>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button className="text-red-600 hover:text-red-900" onClick={() => handleDelete(li?.userId, li?._id, li?.name)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default LineIncharge;
