import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../service/axiosInstance'; // Import the axiosInstance
import Loader from '../components/Loader'; // Import the Loader component
import { toast } from 'react-toastify';
import ENDPOINTS from "../service/endpoint";

const Orders = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [activeTab, setActiveTab] = useState('Customer name')
  const [orderDetails, setOrderDetails] = useState([])

  const fetchOrders = async () => {
    try {
      const response = await axiosInstance.get(ENDPOINTS.ORDER_LIST);
      setOrderDetails(response?.data?.data);      
    } catch (error) {
      console.error('Error fetching orders:', error);

    } finally {
      setLoading(false); // Set loading to false after data fetch is complete
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleDelete = async (orderId) => {
    const confirmed = window.confirm('Are you sure you want to delete this order?');
    if (!confirmed) {
      return; // If user cancels, do nothing
    }

    try {
      await axiosInstance.delete(`${ENDPOINTS.ORDER_LIST}${orderId}`);
      const updatedOrders = orders.filter((order) => order._id !== orderId);
      setOrders(updatedOrders);
      toast.success('Order Deleted Successfully');
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  const handleTabClick = (text) => {
    setActiveTab(text);    
  };

  useEffect(() => {
    const filteredOrders = orderDetails?.filter(order => {
      if (activeTab === 'Customer name') {
        return order?.customerName !== 'self production';
      } else {
        return order?.customerName === 'self production';
      }
    });
    setOrders(filteredOrders);
  }, [activeTab, orderDetails]);
  
  return (
    <div className="mx-9 px-9 mt-2">
      <div className="mb-7 flex justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-slate-900">Orders</h2>
        <button
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          onClick={() => navigate('/orders/addorder')}
        >
          Add Order
        </button>
      </div>

      <div className="order mb-5">
        <button
          className={`p-3 m-2 rounded-lg bg-gray-200 text-gray-700 font-bold hover:bg-sky-500 hover:text-slate-50 hover:bg-opacity-80 ${
            activeTab === 'Customer name' ? 'bg-sky-600 text-slate-50' : 'text-gray-800'
          } rounded`} 
          onClick={() => handleTabClick('Customer name')}
        >
          Customer name 
        </button>

        <button
          className={`p-3 m-2 rounded-lg bg-gray-200 text-gray-700 font-bold hover:bg-sky-500 hover:text-slate-50 hover:bg-opacity-80 ${
            activeTab === 'Self-Production' ? 'bg-sky-600 text-slate-50' : 'text-gray-800'
          } rounded`} 
          onClick={() => handleTabClick('Self-Production')}
        >
          Self-Production 
        </button>
      </div>

      {loading ? (
        <Loader /> // Use the Loader component here
      ) : (
        activeTab === 'Customer name' ? (
          <div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Customer Product Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Factory Product Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Promised Start Date
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Deadline
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Total Quantity
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Quantity Shipped
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Delete</span>
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {orders?.map((order, index) => (
                  <tr key={order._id} className={index % 2 === 0 ? 'bg-white' : 'bg-indigo-300'}>
                    <td className="px-6 py-4 whitespace-nowrap">{order?.customerProductName}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{order?.factoryProductName}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{order?.promisedStartDate}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{order?.deadline}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{order?.totalQuantity}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{order?.shippedQuantity}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{order?.status}</td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button className="text-blue-600 hover:text-blue-900" onClick={() => navigate(`/orders/editorder/${order?._id}`)}>
                        Edit
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button className="text-red-600 hover:text-red-900" onClick={() => handleDelete(order?._id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Factory Product Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Start Date
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    End Date
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Total Quantity
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Delete</span>
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {orders?.map((order, index) => (
                  <tr key={order._id} style={{ background: index % 2 === 0 ? "#fafafa" : "#8fa6ea" }}>
                    <td className="px-6 py-4 whitespace-nowrap">{order?.factoryProductName}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{order?.promisedStartDate}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{order?.deadline}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{order?.totalQuantity}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{order?.status}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button className="text-blue-600 hover:text-blue-900" onClick={() => navigate(`/orders/editorder/${order?._id}`)}>
                        Edit
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button className="text-red-600 hover:text-red-900" onClick={() => handleDelete(order?._id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      )}
    </div>
  );
};

export default Orders;
