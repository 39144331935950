import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import "../styles/calenderpop.css";
import { useNavigate } from "react-router-dom";
import { useDeleteProductionPlan } from "../pages/hooks/productionPlan";
import { toast } from "react-toastify";

const CalenderPop = ({ selectedEvent, closeModal, onDelete }) => {
  console.log("🚀 ~ CalenderPop ~ selectedEvent:", selectedEvent)
  const nav = useNavigate();
  const { mutateAsync, isError, error } = useDeleteProductionPlan();

  const handleDelete = async () => {
    if (window.confirm(`Are you sure you want to delete Production Plan for ${selectedEvent.factoryProductName}?`)) {
      try {
        const response = await mutateAsync({ id: selectedEvent.id });
        toast.success("Deleted Successfully!");
        onDelete(selectedEvent.id);
        closeModal();
        nav('/plan');
      } catch (isError) {
        toast.error(error.response.data.message);
      }
    }
  };

  const formatDate = (date) => moment(date).format("DD-MM-YYYY");

  const convertDateFormat = (dateStr) => {
    const [day, month, year] = dateStr?.split('-');
    return `${year}-${month}-${day}`;
  };


  const calculateWeekdaysFromStart = (startDate) => {
    const start = moment(startDate).endOf('day'); // Use endOf('day') to count at the end of the day
    const end = moment().endOf('day'); // Use endOf('day') to count at the end of the day
    let weekdays = 0;

    while (start.isSameOrBefore(end)) {
      if (start.isoWeekday() !== 7) { // Exclude Sundays (7 is Sunday in ISO week date)
        weekdays++;
      }
    start.add(1, 'day');
    }

    return weekdays;
  };


  const calculateCurrentAvgDailyProd = (shippedQuantity, actualStartDateStr) => {
    console.log("🚀 ~ calculateCurrentAvgDailyProd ~ shippedQuantity:", shippedQuantity)
    const actualStartDate = actualStartDateStr && (convertDateFormat(actualStartDateStr));
    const daysFromStart = calculateWeekdaysFromStart(actualStartDate);
    console.log("🚀 ~ calculateCurrentAvgDailyProd ~ daysFromStart:", daysFromStart)
    return daysFromStart > 0 ? (shippedQuantity / daysFromStart).toFixed(2) : 0;
  };
   
  const currentAvgDailyProd = calculateCurrentAvgDailyProd(
    selectedEvent?.productOutputQuantity,
    selectedEvent.actualStartDate
  );

  const calculateEndDateExcludingSundays = (remainingQuantity, avgDailyProd) => {
    let daysRequired = remainingQuantity / avgDailyProd;
    let currentDate = moment().endOf('day');
    let endDate = currentDate.clone();
  
    while (daysRequired > 0) {
      endDate.add(1, 'day');
      if (endDate.isoWeekday() !== 7) { // Skip Sundays
        daysRequired--;
      }
    }
  
    return endDate.format('DD-MM-YYYY');
  };
  
  const endDateBasedOnCurrentAvgDailyProd = currentAvgDailyProd > 0
    ? calculateEndDateExcludingSundays(
        selectedEvent.totalQuantity - selectedEvent.productOutputQuantity,
        currentAvgDailyProd
      )
    : 'Not started';

  const handleEdit = () => {
    nav(`/plan/editproductionplan/${selectedEvent?.id}`);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="header">
          <i className="fa-solid fa-trash-can fa-lg" onClick={handleDelete} style={{ cursor: 'pointer' }}></i>
          <i className="fa-solid fa-pencil fa-lg" onClick={handleEdit} style={{ cursor: 'pointer' }}></i>
          <i
            className="fa-solid fa-xmark fa-xl"
            onClick={closeModal}
            style={{ cursor: "pointer" }}
          ></i>
        </div>
        <div className="body-content">
          <p>
            Customer Name: <span>{selectedEvent.customerName}</span>
          </p>
          <p>
            Factory Product Name: <span>{selectedEvent.factoryProductName}</span>
          </p>
          <p>
            Promised Start Date: <span>{selectedEvent?.promiseStartDate}</span>
          </p>
          <p>
            Promised End Date: <span>{selectedEvent.promiseEndDate}</span>
          </p>
          <p>
            Total Quantity: <span>{selectedEvent.totalQuantity}</span>
          </p>
          <p>
            Assembly Line: <span>{selectedEvent.assemblyLineLineName}</span>
          </p>
          <p>
            Planned Avg. Daily Prod.: <span>{selectedEvent.averageDailyProduction}</span>
          </p>
          <p>
            Actual Start Date: <span>{formatDate(selectedEvent.actualStartDate)}</span>
          </p>
          <p>
            Planned End Date: <span>{formatDate(selectedEvent.projectedEndDate)}</span>
          </p>
          <p>
            Current Avg Daily Prod.:<span> {currentAvgDailyProd === 0 ? 'not started' : currentAvgDailyProd} </span>
          </p>
          <p>End Date Based on Curr. Avg Daily Prod.: <span>{endDateBasedOnCurrentAvgDailyProd}</span></p>
        </div>
      </div>
    </div>
  );
};

CalenderPop.propTypes = {
  selectedEvent: PropTypes.shape({
    customerName: PropTypes.string.isRequired,
    factoryProductName: PropTypes.string.isRequired,
    actualStartDate: PropTypes.instanceOf(Date).isRequired,
    assemblyLineLineName: PropTypes.string.isRequired,
    assignAssemblyLineId: PropTypes.string.isRequired,
    averageDailyProduction: PropTypes.number.isRequired,
    customerId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired,
    orderName: PropTypes.string.isRequired,
    projectedEndDate: PropTypes.instanceOf(Date).isRequired,
    promiseEndDate: PropTypes.instanceOf(Date).isRequired,
    promiseStartDate: PropTypes.instanceOf(Date).isRequired,
    productOutputQuantity: PropTypes.number.isRequired,
    totalQuantity: PropTypes.number.isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CalenderPop;
