import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CreateableSelectWithDelete from "../../components/CreateableSelectWithDelete";
import ENDPOINTS from "../../service/endpoint";
import { useRawMaterialList } from "../hooks/rawmaterial";
import { ClipLoader } from "react-spinners";
import { Tooltip } from "react-tooltip";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import axiosInstance from "../../service/axiosInstance";

const AddProduct = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = Cookies.get("accessToken");

  const navigate = useNavigate();

  const [productName, setProduct] = useState("");
  const [rawMaterials, setRawMaterials] = useState([
    { name: "", quantity: "" },
  ]);
  const [processes, setProcesses] = useState([{ process: "", docUrl: "" }]);
  const [category, setCategory] = useState(null);
  const [errors, setErrors] = useState({});
  const [warning, setWarning] = useState("");
  const [rawMaterial, setRawMaterial] = useState([]);
  console.log("🚀 ~ AddProduct ~ rawMaterial:", rawMaterial)

  const { data, isLoading, mutate, isSuccess, isError, error } =
    useRawMaterialList();

  useEffect(() => {
    if (!isSuccess) {
      mutate();
    }

    if (isError) {
      toast.error(error.response.data.message);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (data) {
      setRawMaterial(data?.data);
    }
  }, [data]);

  const handleAddRawMaterial = () => {
    setRawMaterials([...rawMaterials, { name: "", quantity: "" }]);
  };
  const handleAddProcesses = () => {
    setProcesses([...processes, { id: Date.now(), process: "", docUrl: "" }]);
  };

  const handleRemoveRawMaterial = (index) => {
    const updatedMaterials = [...rawMaterials];
    updatedMaterials.splice(index, 1);
    setRawMaterials(updatedMaterials);
  };

  const handleRemoveProcesses = (id) => {
    const updatedProcesses = processes.filter((process) => process.id !== id);
    setProcesses(updatedProcesses);
  };

  const handleFileChange = async (index, e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        // 10 MB
        setWarning("File size exceeds 10 MB. Please upload a smaller file.");
        return;
      } else {
        setWarning("");
      }

      try {
        const data = new FormData();
        data.append("file", file);

        const config = {
          method: "post",
          url: `${baseURL}${ENDPOINTS.UPLOAD_DOC}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };

        const response = await axios.request(config);
        const fileUrl = response.data.viewUrl; // Adjust based on actual response

        const updatedProcesses = [...processes];
        updatedProcesses[index].docUrl = fileUrl;
        setProcesses(updatedProcesses);
      } catch (error) {
        console.error("Error uploading document:", error);
        setErrors({ ...errors, document: "Failed to upload document" });
        toast.error("Failed to upload document");
      }
    }
  };

  const handleMaterialNameChange = (index, value) => {
    const updatedMaterials = [...rawMaterials];
    updatedMaterials[index].name = value || "";
    setRawMaterials(updatedMaterials);
  };

  const handleMaterialQuantityChange = (index, value) => {
    const updatedMaterials = [...rawMaterials];
    updatedMaterials[index].quantity = value;
    setRawMaterials(updatedMaterials);
  };

  const handleCategoryChange = (selectedOption) => {
    setCategory(selectedOption?.label || null); // Update the category state
  };

  const isNameUnique = async (productName) => {
    try {
      const response = await axiosInstance.post(
        `${ENDPOINTS.PRODUCT_CHECK_NAME}`,
        { productName: productName.toLowerCase() }
      );
      return response?.data?.data?.isUnique;
    } catch (error) {
      console.error("Error checking Product Name uniqueness:", error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const unique = await isNameUnique(productName);
    if (!unique) {
      setErrors({ ...errors, productName: "Product Name already exists!" });
      return;
    }

    try {
      const productData = {
        productName: productName,
        rawMaterials: rawMaterials,
        processes: processes,
        category: category,
      };

      const response = await axiosInstance.post(
        `${ENDPOINTS.PRODUCT_LIST}`,
        productData
      );

      if (response.data.message) {
        toast.success("Product Added Successfully!");
        navigate("/product");
      } else {
        alert(response.data.error);
      }
    } catch (error) {
      console.error("Error adding product:", error);

      if (error.response && error.response.data.error) {
        alert(`Error adding product: ${error.response.data.error}`);
      } else {
        alert("Error adding Product, Trying Again!");
      }
    }
  };

  return (
    <div>
      <div className="mx-3">
        <button
          type="button"
          onClick={() => {
            navigate("/product");
          }}
          className="ml-8 w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto"
        >
          <svg
            className="w-5 h-5 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>
          <span>Go back</span>
        </button>
      </div>

      <div className="px-20 py-7">
        <p className="text-2xl font-bold mb-4 text-gray-900">Add Product</p>

        <form onSubmit={handleSubmit}>
          <div className="mx-3 mt-2">
            <div
              className="grid grid-cols-2 gap-2"
              style={{ width: "calc(100% - 40px)" }}
            >
              <div className="col-span-1">
                <label
                  htmlFor="product_name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Product Name
                </label>
                <input
                  onChange={(e) => {
                    setProduct(e.target.value);
                  }}
                  type="text"
                  id="product_name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                  placeholder=""
                  required
                />
                {errors.productName && (
                  <p className="text-red-500">{errors.productName}</p>
                )}
              </div>
              <div className="col-span-1">
                <label className="mb-2">Product Category</label>
                <div className="p-1">
                  <CreateableSelectWithDelete
                    getArticlesUrl={`${baseURL}${ENDPOINTS.PRODUCT_CATEGORY_LIST}`}
                    addArticleUrl={`${baseURL}${ENDPOINTS.PRODUCT_CATEGORY_LIST}`}
                    deleteArticleUrl={`${baseURL}${ENDPOINTS.PRODUCT_CATEGORY_DELETE}`}
                    token={token}
                    keyProp="category"
                    onChange={handleCategoryChange}
                    placeholder="Product Category"
                    value={category}
                  />
                </div>
              </div>
            </div>

            {/* Raw Materials  */}
            <div className="mt-2">
              <div
                className="grid grid-cols-2"
                style={{ width: "calc(100% - 40px)" }}
              >
                <label className="mb-1">SKU Name</label>
                <label className="mb-1">SKU Quantity</label>
              </div>

              {rawMaterials.map((material, index) => (
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  key={index}
                >
                  <div
                    className="grid grid-cols-2 gap-2"
                    style={{ width: "calc(100% - 40px)" }}
                  >
                    <select
                      id="materialId"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 h-10 "
                      value={material.name}
                      onChange={(e) =>
                        handleMaterialNameChange(index, e.target.value)
                      }
                      placeholder="Material Name"
                    >
                      <option value=""> Select SKU Name </option>

                      {isLoading ? (
                        <option>
                          <ClipLoader size={40} />
                        </option>
                      ) : (
                        rawMaterial?.map((raw) => (
                          <option key={raw?._id} value={raw?.materialName}>
                            {raw?.materialName}
                          </option>
                        ))
                      )}
                    </select>

                    <div className="flex">
                      <input
                        className="col-span-1 mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 h-10"
                        type="text"
                        value={material.quantity}
                        onChange={(e) =>
                          handleMaterialQuantityChange(index, e.target.value)
                        }
                        placeholder="Quantity"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "40px",
                    }}
                  >
                    {" "}
                    {rawMaterials.length > 1 && (
                      <button
                        onClick={() => handleRemoveRawMaterial(index)}
                        type="button"
                        className="m-2 border-2 border-gray-700 hover:bg-slate-200 hover:text-white font-medium rounded-full text-sm p-1 w-6 h-6"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13"
                          height="13"
                          viewBox="0 0 24 24"
                        >
                          <path fillRule="evenodd" d="M2 11h20v2H2z"></path>
                        </svg>
                      </button>
                    )}
                    {index === rawMaterials?.length - 1 && (
                      <button
                        onClick={handleAddRawMaterial}
                        type="button"
                        className="m-2 border-2 border-gray-700 hover:bg-slate-200 hover:text-white font-medium rounded-full text-sm p-1 w-6 h-6"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13"
                          height="13"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
                          ></path>
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Processes  */}
            <div className="mt-2">
              <div
                className="grid grid-cols-2"
                style={{ width: "calc(100% - 40px)" }}
              >
                <label className="mb-1">Process Name</label>
                <label className="mb-1">Process File</label>
              </div>

              {processes.map((process, index) => (
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  key={index}
                >
                  <div
                    className="grid grid-cols-2 gap-2"
                    style={{ width: "calc(100% - 40px)" }}
                  >
                    <input
                      className="col-span-1 mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 h-10"
                      type="text"
                      value={process.process}
                      onChange={(e) => {
                        const updatedProcesses = [...processes];
                        updatedProcesses[index].process = e.target.value;
                        setProcesses(updatedProcesses);
                      }}
                      placeholder="Process Name"
                    />

                    <div className="flex">
                      <input
                        className="col-span-1 mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 h-10"
                        type="file"
                        onChange={(e) => handleFileChange(index, e)}
                        accept=".pdf,.doc,.docx,.txt"
                        key={process?.id}
                      />

                      {process?.docUrl && (
                        <>
                          <a
                            href={process?.docUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 underline m-2"
                            data-tooltip-id={`tooltip-${index}`}

                            // title={process?.docUrl.substring(process?.docUrl.lastIndexOf('/') + 1)}
                            // title={process?.docUrl}
                          >
                            View
                          </a>
                          <Tooltip
                            id={`tooltip-${index}`}
                            place="top"
                            effect="solid"
                            className="custom-tooltip"
                          >
                            <div>
                              <p>
                                {" "}
                                {process?.docUrl.substring(
                                  process?.docUrl.lastIndexOf("/") + 1
                                )}
                              </p>
                            </div>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "40px",
                    }}
                  >
                    {processes.length > 1 && (
                      <button
                        onClick={() => handleRemoveProcesses(process?.id)}
                        type="button"
                        className="m-2 border-2 border-gray-700 hover:bg-slate-200 hover:text-white font-medium rounded-full text-sm p-1 w-6 h-6"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13"
                          height="13"
                          viewBox="0 0 24 24"
                        >
                          <path fillRule="evenodd" d="M2 11h20v2H2z"></path>
                        </svg>
                      </button>
                    )}
                    {index === processes?.length - 1 && (
                      <button
                        onClick={handleAddProcesses}
                        type="button"
                        className="m-2  border-2 border-gray-700 hover:bg-slate-200 hover:text-white font-medium rounded-full text-sm p-1 w-6 h-6"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13"
                          height="13"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
                          ></path>
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className=" flex justify-center">
              <button
                className="px-5 py-2.5 mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto text-center"
                type="submit"
              >
                Add Product
              </button>
            </div>
          </div>
        </form>

        {warning && <div className="mt-4 text-red-500 text-sm">{warning}</div>}
      </div>
    </div>
  );
};

export default AddProduct;

