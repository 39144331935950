import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie'; 
import { toast } from 'react-toastify';
import axiosInstance from '../../service/axiosInstance';
import ENDPOINTS from "../../service/endpoint";

const EditCustomer = () => {
    const navigate = useNavigate();
    const { userId, id } = useParams();

    const [formData, setFormData] = useState({
        name: '',
        companyName: '',
        companyAddress: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            pinCode: ''
        },
        contactPerson: '',
        website: '',
        email: '',
        phoneNumber: '',
        billingAddress: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            pinCode: ''
        },
        paymentTerms: '',
        sameAsCompanyAddress: false,
        isActive: '',
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const response = await axiosInstance.get(`${ENDPOINTS.CUSTOMER_LIST}${id}`);
                setFormData(response?.data?.data);
            } catch (error) {
                console.error('Error fetching customer data:', error);
                
            }
        };
        fetchCustomer();
    }, [id]);
 
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const [category, field] = name.split('.');
        
        if (category === 'companyAddress' || category === 'billingAddress') {
            setFormData(prevState => ({
                ...prevState,
                [category]: {
                    ...prevState[category],
                    [field]: value
                }
            }));
        } else if (type === 'checkbox') {
            setFormData(prevState => ({
                ...prevState,
                [name]: checked
            }));
        } else if (name === 'website') {
            let websiteValue = value.trim();
            if (!websiteValue.startsWith('http://') && !websiteValue.startsWith('https://')) {
                websiteValue = `https://${websiteValue}`;
            }
            setFormData(prevState => ({
                ...prevState,
                [name]: websiteValue
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };    

    const validateForm = () => {
        let errors = {};
        if (!formData.name.trim()) errors.name = 'Customer Name is required';
        if (!formData.companyName.trim()) errors.companyName = 'Company Name is required';
        const addressFields = ['addressLine1', 'city', 'state', 'pinCode'];
        if (!formData.companyAddress.addressLine1.trim() || !formData.companyAddress.city.trim() || !formData.companyAddress.state.trim() || !formData.companyAddress.pinCode.trim()) {
            addressFields.forEach(field => {
                if (!formData.companyAddress[field].trim()) {
                    errors[`companyAddress.${field}`] = `Company ${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
                }
            });
        }
        if (!formData.contactPerson.trim()) errors.contactPerson = 'Contact Person is required';
        if (!formData.website.trim()) errors.website = 'Website is required. Please enter a valid URL like https://www.example.com';
        if (!formData.email.trim()) errors.email = 'Email is required. Please enter a valid email address';
        if (!formData.phoneNumber.trim() || formData.phoneNumber.trim().length !== 10 || isNaN(formData.phoneNumber.trim())) errors.phoneNumber = 'Phone Number must be a 10-digit number';
        if (!formData.billingAddress.addressLine1.trim() && !formData.sameAsCompanyAddress) errors.billingAddress = 'Billing Address Line 1 is required if different from Company Address';
        if (!formData.billingAddress.city.trim() && !formData.sameAsCompanyAddress) errors.billingAddressCity = 'Billing City is required if different from Company Address';
        if (!formData.billingAddress.state.trim() && !formData.sameAsCompanyAddress) errors.billingAddressState = 'Billing State is required if different from Company Address';
        if (!formData.billingAddress.pinCode.trim() && !formData.sameAsCompanyAddress) errors.billingAddressPinCode = 'Billing PIN Code is required if different from Company Address';
        if (!formData.paymentTerms.trim()) errors.paymentTerms = 'Payment Terms are required';
    
        return errors;
    };    

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        if (formData.sameAsCompanyAddress) {
            formData.billingAddress = { ...formData.companyAddress };
        }

        

        try {
            await axiosInstance.put(`${ENDPOINTS.CUSTOMER_LIST}${userId}/${id}`, formData);
            toast.success('Customer updated successfully!');
            navigate('/customers'); 
        } catch (error) {
            console.error('Error updating customer:', error);
        }
    };

    return (
        <div>
            <div className='mx-3'>
                <button type="button" onClick={() => { navigate('/customers') }} className="ml-8 w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto hover:bg-gray-100">
                    <svg className="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                    </svg>
                    <span>Go back</span>
                </button>
            </div>

            <div className='px-20 py-6 mx-10'>
                <p className="text-2xl font-bold mb-4 text-gray-900">Edit Customer</p>

                <form onSubmit={handleSubmit} className='grid grid-cols-3 gap-3'>
                    <div className="mb-4">
                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Customer Name</label>
                        <input type="text" id="name" name="name" className={`bg-gray-50 border ${errors.name ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={formData.name} onChange={handleChange} />
                        {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="companyName" className="block mb-2 text-sm font-medium text-gray-900">Company Name</label>
                        <input type="text" id="companyName" name="companyName" className={`bg-gray-50 border ${errors.companyName ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={formData.companyName} onChange={handleChange} />
                        {errors.companyName && <p className="text-red-500 text-xs mt-1">{errors.companyName}</p>}
                    </div>

                    <h3 className="text-xl font-semibold mb-2 text-gray-900 col-span-3">Company Address</h3>
                    <div className="mb-4 col-span-3">
                        <label htmlFor="companyAddress.addressLine1" className="block mb-2 text-sm font-medium text-gray-900">Address Line 1</label>
                        <input
                            type="text"
                            id="companyAddress.addressLine1"
                            name="companyAddress.addressLine1"
                            className={`bg-gray-50 border ${errors['companyAddress.addressLine1'] ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                            value={formData.companyAddress.addressLine1}
                            onChange={handleChange}
                        />
                        {errors['companyAddress.addressLine1'] && <p className="text-red-500 text-xs mt-1">{errors['companyAddress.addressLine1']}</p>}
                    </div>
                    <div className="mb-4 col-span-3">
                        <label htmlFor="companyAddress.addressLine2" className="block mb-2 text-sm font-medium text-gray-900">Address Line 2</label>
                        <input
                            type="text"
                            id="companyAddress.addressLine2"
                            name="companyAddress.addressLine2"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            value={formData.companyAddress.addressLine2}
                            onChange={handleChange}
                        />
                    </div>

                    
                    <div className="mb-4">
                        <label htmlFor="companyAddress.city" className="block mb-2 text-sm font-medium text-gray-900">City</label>
                        <input type="text" id="companyAddress.city" name="companyAddress.city" className={`bg-gray-50 border ${errors.companyAddressCity ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={formData.companyAddress.city} onChange={handleChange} />
                        {errors.companyAddressCity && <p className="text-red-500 text-xs mt-1">{errors.companyAddressCity}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="companyAddress.state" className="block mb-2 text-sm font-medium text-gray-900">State</label>
                        <input type="text" id="companyAddress.state" name="companyAddress.state" className={`bg-gray-50 border ${errors.companyAddressState ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={formData.companyAddress.state} onChange={handleChange} />
                        {errors.companyAddressState && <p className="text-red-500 text-xs mt-1">{errors.companyAddressState}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="companyAddress.pinCode" className="block mb-2 text-sm font-medium text-gray-900">PIN Code</label>
                        <input type="text" id="companyAddress.pinCode" name="companyAddress.pinCode" className={`bg-gray-50 border ${errors.companyAddressPinCode ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={formData.companyAddress.pinCode} onChange={handleChange} />
                        {errors.companyAddressPinCode && <p className="text-red-500 text-xs mt-1">{errors.companyAddressPinCode}</p>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="contactPerson" className="block mb-2 text-sm font-medium text-gray-900">Contact Person</label>
                        <input type="text" id="contactPerson" name="contactPerson" className={`bg-gray-50 border ${errors.contactPerson ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={formData.contactPerson} onChange={handleChange} />
                        {errors.contactPerson && <p className="text-red-500 text-xs mt-1">{errors.contactPerson}</p>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="website" className="block mb-2 text-sm font-medium text-gray-900">Website</label>
                        <input type="url" id="website" name="website" className={`bg-gray-50 border ${errors.website ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={formData.website} onChange={handleChange} />
                        {errors.website && <p className="text-red-500 text-xs mt-1">{errors.website}</p>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                        <input type="email" id="email" name="email" className={`bg-gray-50 border ${errors.email ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={formData.email} onChange={handleChange} />
                        {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="phoneNumber" className="block mb-2 text-sm font-medium text-gray-900">Phone Number</label>
                        <input type="tel" id="phoneNumber" name="phoneNumber" className={`bg-gray-50 border ${errors.phoneNumber ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={formData.phoneNumber} onChange={handleChange} />
                        {errors.phoneNumber && <p className="text-red-500 text-xs mt-1">{errors.phoneNumber}</p>}
                    </div>

                    <h3 className="col-span-3 text-xl font-semibold mb-2 text-gray-900">Billing Address</h3>
                    <div className="mb-4 flex items-center col-span-3">
                        <input type="checkbox" id="sameAsCompanyAddress" name="sameAsCompanyAddress" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 mr-2" checked={formData.sameAsCompanyAddress} onChange={handleChange} />
                        <label htmlFor="sameAsCompanyAddress" className="text-sm font-medium text-gray-900">Same as company address</label>
                    </div>
                    {!formData.sameAsCompanyAddress && (
                        <>
                            <div className='col-span-3'>
                                <div className="mb-4">
                                    <label htmlFor="billingAddress.addressLine1" className="block mb-2 text-sm font-medium text-gray-900">Address Line 1</label>
                                    <input type="text" id="billingAddress.addressLine1" name="billingAddress.addressLine1" className={`bg-gray-50 border ${errors.billingAddress ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={formData.billingAddress.addressLine1} onChange={handleChange} />
                                    {errors.billingAddress && <p className="text-red-500 text-xs mt-1">{errors.billingAddress}</p>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="billingAddress.addressLine2" className="block mb-2 text-sm font-medium text-gray-900">Address Line 2</label>
                                    <input type="text" id="billingAddress.addressLine2" name="billingAddress.addressLine2" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" value={formData.billingAddress.addressLine2} onChange={handleChange} />
                                </div>
                            </div>

                            <div className="mb-4">
                                <label htmlFor="billingAddress.city" className="block mb-2 text-sm font-medium text-gray-900">City</label>
                                <input type="text" id="billingAddress.city" name="billingAddress.city" className={`bg-gray-50 border ${errors.billingAddressCity ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={formData.billingAddress.city} onChange={handleChange} />
                                {errors.billingAddressCity && <p className="text-red-500 text-xs mt-1">{errors.billingAddressCity}</p>}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="billingAddress.state" className="block mb-2 text-sm font-medium text-gray-900">State</label>
                                <input type="text" id="billingAddress.state" name="billingAddress.state" className={`bg-gray-50 border ${errors.billingAddressState ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={formData.billingAddress.state} onChange={handleChange} />
                                {errors.billingAddressState && <p className="text-red-500 text-xs mt-1">{errors.billingAddressState}</p>}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="billingAddress.pinCode" className="block mb-2 text-sm font-medium text-gray-900">PIN Code</label>
                                <input type="text" id="billingAddress.pinCode" name="billingAddress.pinCode" className={`bg-gray-50 border ${errors.billingAddressPinCode ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={formData.billingAddress.pinCode} onChange={handleChange} />
                                {errors.billingAddressPinCode && <p className="text-red-500 text-xs mt-1">{errors.billingAddressPinCode}</p>}
                            </div>
                        </>
                    )}

                    {/* Payment Terms */}
                    <div className="mb-4">
                        <label htmlFor="paymentTerms" className="block mb-2 text-sm font-medium text-gray-900">Payment Terms</label>
                        <input type="text" id="paymentTerms" name="paymentTerms" className={`bg-gray-50 border ${errors.paymentTerms ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={formData.paymentTerms} onChange={handleChange} />
                        {errors.paymentTerms && <p className="text-red-500 text-xs mt-1">{errors.paymentTerms}</p>}
                    </div>

                    <div className="mb-4 col-span-3 flex items-center">
                        <input
                        type="checkbox"
                        id="isActive"
                        name="isActive"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                        checked={formData.isActive}
                        onChange={handleChange}
                        />
                        <label
                        htmlFor="isActive"
                        className="ml-2 text-md font-medium text-gray-900"
                        >
                        Active (Username and Password will be sent to the Customer Email)
                        </label>
                    </div>

                    {/* Submit Button */}
                    <div className='col-span-3'>
                        <button type="submit" className="bg-blue-500 text-white text-sm font-medium rounded-lg py-2.5 px-5 focus:ring-4 focus:ring-blue-300 focus:outline-none">Update Customer</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditCustomer;
