import { useMutation, useQuery } from "react-query";
import axiosInstance from "../../service/axiosInstance.js";
import ENDPOINTS from "../../service/endpoint.js";

// // addemployee api call (POST WITH DATA)---
// // const fetchSignup = async (params) => {
// //     try {
// //       const { data } = await axiosInstance({
// //         method: "post",
// //         url: ENDPOINTS.ADDEMPLOYEE,
// //         data: params,
// //         headers: { "Content-Type": "application/json" },
// //       });
// //         console.log("🚀 ~ fetchSignup ~ data:", data)
      
// //       return data;
// //     } catch (error) {
// //       // Handle errors if necessary
// //       console.error("Error during signup:", error);
// //       throw error;
// //     }
// //   };

// // const useSingUp = () => {
// //   return useMutation({
// //     queryKey: ["useSingUp"],
// //     mutationFn: (params) => fetchSignup(params),
// //   });
// // };

// // // login api call (POST WITH DATA)---
// // const fetchLogin = async (params) => {
// //   const { data } = await axiosInstance({
// //     method: "post",
// //     url: ENDPOINTS.LOGIN,
// //     data: params,
// //     headers: { "Content-Type": "application/json" },
// //   });
// //   return data;
// // };

// // const useLogin = () => {
// //   return useMutation({
// //     queryKey: ["useLogin"],
// //     mutationFn: (params) => fetchLogin(params),
// //   });
// // };


// GET PROFILE DATA
const fetchAssemblyLine = async (params) => {
    const { data } = await axiosInstance({
        method: "get",
        url: ENDPOINTS.ASSEMBLY_LINE,
        headers: { "Content-Type": "application/json" },
      });
      return data;
    };
    
    const useAssemblyLine = () => {
      return useQuery({
        
        queryKey: ["useAssemblyLine"],
        queryFn: (params) => fetchAssemblyLine(params),
      });
    };

    const fetchSingleAssemblyLine = async (params) => {
      console.log("🚀 ~ fetchSingleAssemblyLine ~ params:", params)
      const { data } = await axiosInstance({
          method: "get",
          url: `${ENDPOINTS.SINGLE_ASSEMBLY_LINE}${params.id}`,
          headers: { "Content-Type": "application/json" },
        });
        return data;
      };
      
      const useSingleAssemblyLine = () => {
        return useMutation({
          
          queryKey: ["useSingleAssemblyLine"],
          mutationFn: (params) => fetchSingleAssemblyLine(params),
        });
      };

      const fetchAssemblyLineList = async (params) => {
        console.log("🚀 ~ fetchSingleAssemblyLine ~ params:", params)
        const { data } = await axiosInstance({
            method: "get",
            url: ENDPOINTS.ASSEMBLY_LINE_LIST,
            headers: { "Content-Type": "application/json" },
          });
          return data;
        };
        
        const useAssemblyLineList = () => {
          return useMutation({
            
            queryKey: ["useAssemblyLineList"],
            mutationFn: (params) => fetchAssemblyLineList(params),
          });
        };
export{
useAssemblyLine,
useSingleAssemblyLine,
useAssemblyLineList,
}