import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useFormik } from "formik";
import {
  initialValuesProductionIncharge,
  validationSchemaProductionIncharge,
} from "../../validation";
import { useEditProductionIncharge } from "../hooks/productionIncharge";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { useGetLineIncharge } from "../hooks/lineIncharge";

const EditProductionIncharge = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const productDetails = location.state;
  const { mutateAsync, isLoading, isSuccess, isError, data, error } =
    useEditProductionIncharge();
  const [selectedLines, setSelectedLines] = useState(
    productDetails.selectLI
      ? productDetails.selectLI.map((item) => ({
        value: item.liID,
        label: item.nameLI,
      }))
      : []
  );

  const {
    mutate,
    data: linedata,
    isSuccess: lineSuccess,
    isError: lineError,
    error: lineErrorData,
  } = useGetLineIncharge();

  useEffect(() => {
    if (!lineSuccess) {
      mutate();
    }
    if (lineError) {
      toast.error(lineErrorData.response.data.message);

    }
  }, [lineSuccess, lineError, lineErrorData, mutate, navigate]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#F9FAFB" : "#F9FAFB", // Light gray background
      borderColor: state.isFocused ? "" : "#D1D5DB", // Black border
      boxShadow: state.isFocused ? "0 0 0 1px #000000" : "none",
      "&:hover": {
        borderColor: "#000000", // Black border on hover
      },
      height: "40px", // Adjust width as needed
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#d1d5db", // Light gray background for selected options
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#000", // Black text color for selected options
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#000", // Black remove icon
      "&:hover": {
        backgroundColor: "#000",
        color: "#fff",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };
  const lineOptions = linedata
    ? linedata?.data?.map((option) => ({
      value: option._id,
      label: option.name,
    }))
    : [];

  useEffect(() => {
    if (isSuccess) {
      const { status, message } = data;
      toast.success(message);

      if (status === 200) {
        resetForm();

        navigate("/pi");
      }
    }

    if (isError) {
      toast.error(error.response.data.message);

    }
  }, [isError, isSuccess, data, error, navigate]);

  const {
    resetForm,
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: productDetails.name,
      selectLI: productDetails.selectLI,
      email: productDetails.email,
      phone: productDetails.phone,
      username: productDetails.username,
    },

    validationSchema: validationSchemaProductionIncharge,
    onSubmit: async (values) => {
      try {
        const payload = {
          name: values.name,

          selectLI: values.selectLI,
          email: values.email,
          phone: values.phone,
          username: values.username,
        };
        const response = await mutateAsync({
          payload,
          userId: productDetails?.userId,
          Id: productDetails?._id,
        });
      } catch (error) {
        console.error("Error during login:", error);
      }
    },
  });

  const handleLineSelect = (selectedOptions) => {
    setSelectedLines(selectedOptions);
    formik.setFieldValue("selectLI", selectedOptions);
  };

  return (
    <div>
      <div className="mx-3 mt-7">
        <button
          type="button"
          onClick={() => {
            navigate("/pi");
          }}
          className="ml-8 mt-3 w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
        >
          <svg
            className="w-5 h-5 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>
          <span>Go back</span>
        </button>
      </div>

      <div className="px-40 py-10" style={{ paddingTop: "10px" }}>
        <h2
          className="text-3xl font-extrabold tracking-tight text-slate-900 mb-7"
          style={{}}
        >
          Edit Production Incharge
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <label
                htmlFor="first_name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Name*
              </label>
              <input
                type="text"
                id="first_name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder=""
                name="name"
                value={values.name}
                onChange={handleChange}
              />
              {touched.name && errors.name && (
                <p className="text-red-500">{errors.name}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="last_name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Select LI*
              </label>
              <Select
                id="selectLI"
                name="selectLI"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder=""
                options={lineOptions.map((option) => ({
                  value: option.value,
                  label: option.label,
                }))}
                isMulti
                value={selectedLines}
                onChange={(selectedOptions) => {
                  setSelectedLines(selectedOptions);
                  setFieldValue(
                    "selectLI",
                    selectedOptions.map((option) => ({
                      // nameLI: option.label,
                      liID: option.value,
                    }))
                  );
                }}
                styles={customStyles}
              />
              {touched.selectLI && errors.selectLI && (
                <p className="text-red-500">{errors.selectLI}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="company"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Email*
              </label>
              <input
                type="text"
                id="company"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder=""
                name="email"
                value={values.email}
                onChange={handleChange}
              />
              {touched.email && errors.email && (
                <p className="text-red-500">{errors.email}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="company"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Phone No.*
              </label>
              <input
                type="text"
                id="company"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder=""
                name="phone"
                value={values.phone}
                onChange={handleChange}
              />
              {touched.phone && errors.phone && (
                <p className="text-red-500">{errors.phone}</p>
              )}
            </div>
          </div>
          <div
            className="divider"
            style={{
              height: "1px",
              background: "#000000",
              width: "100%",
              margin: "10px 0px",
            }}
          ></div>
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              paddingRight: "10px",
              gap: "10px",
              marginBottom: "10px",
            }}
          >
            <h2
              className="text-3xl font-extrabold tracking-tight text-slate-900 mb-2"
              style={{}}
            >
              Login Details
            </h2>

            <div>
              <label
                htmlFor="company"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                User Name
              </label>
              <input
                type="text"
                id="company"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder=""
                name="username"
                value={values.username}
                onChange={handleChange}
                readOnly
              />
              {touched.username && errors.username && (
                <p className="text-red-500">{errors.username}</p>
              )}
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              disabled={isLoading}
            >
              {isLoading ? (
                <BeatLoader size={8} color={"#ffffff"} />
              ) : (
                "Edit PI User"
              )}
            </button>
          </div>{" "}
        </form>
      </div>
    </div>
  );
};

export default EditProductionIncharge;
