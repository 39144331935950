const ENDPOINTS = {
  LOGIN: "/api/login/",
  // USER_DETAILS:'/api/register',
  EMPLOYEE_LIST: "/api/employees/",
  PRODUCTION_RATE_GRAPH: "/api/production-graph/",
  ASSEMBLY_LINE: "/api/assembly-lines/",
  ASSEMBLY_LINE_LIST: "/api/factory-setup/",
  SINGLE_ASSEMBLY_LINE: "/api/assembly-lines/",
  PRODUCTION_INCHARGE_LIST: "/api/productionincharge/",
  PRODUCTION_INCHARGE_ADD: "/api/productionincharge/",
  PRODUCTION_INCHARGE_DELETE: "/api/productionincharge/",
  PRODUCTION_INCHARGE_EDIT: "/api/productionincharge/",
  GET_USER_BY_ID: "/api/register",
  ADD_PRODUCTION_PLAN: "/api/productionPlan/",
  PRODUCTION_PLAN_LIST: "/api/productionPlan/",
  SINGLE_PRODUCTION_PLAN: "/api/productionPlan/document/",
  EDIT_PRODUCTION_PLAN: "/api/productionPlan/",
  DELETE_PRODUCTION_PLAN: "/api/productionPlan/",
  ADD_MATERIAL_NAME: "/api/materialName/",
  MATERIAL_NAME_LIST: "/api/materialName/",
  MATERIAL_NAME_DELETE: "/api/materialName/",
  RAW_MATERIAL_LIST: "/api/rawMaterial/",
  DELETE_RAW_MATERIAL: "/api/rawMaterial/",
  ADD_RAW_MATERIAL: "/api/rawMaterial/",
  EDIT_RAW_MATERIAL: "/api/rawMaterial/",
  SINGLE_RAW_MATERIAL: "/api/rawMaterial/document/",
  LINE_WORKERS_LIST: "/api/employees/",
  AVERAGE_FAIL_RATE: "/api/average-fail-rate/",
  ADD_VARIANT_NAME: "/api/variantName/",
  VARIANT_NAME_LIST: "/api/variantName/",
  VARIANT_NAME_DELETE: "/api/variantName/",
  UPLOAD_DOC: "/api/s3-bucket/upload-docs/",
  SEND_EMAIL: "/api/send-email/",
  REGISTER_USER: "/api/register/",
  ORDER_LIST: "/api/order/",
  TOTAL_ORDERS_QUANTITIES: "/api/order/total-orders-quantities/",
  CURRENT_ORDERS: "/api/order/current-orders/",

  VARIANT_NAME_ADD: "/api/order/variant/",
  VARIANT_NAME_GET: "/api/order/variant/",
  VARIANT_NAME_REMOVE: "/api/order/variant/",

  CUSTOMER_ORDER_LIST: "/api/order/customer-orders/",
  CUSTOMER_PRODUCT_NAMES: "/api/customerProductName/",

  CUSTOMER_LIST: "/api/customer/",
  CUSTOMER_CHECK_NAME: "/api/customer/check-name/",

  PRODUCT_LIST: "/api/product/",
  PRODUCT_CHECK_NAME: "/api/product/check-name/",
  PRODUCT_CATEGORY_LIST: "/api/product/category/",
  PRODUCT_CATEGORY_DELETE: "/api/product/category",

  LINE_INCHARGE_LIST: "/api/line-incharge/",
  LINE_INCHARGE_CHECK_NAME: "/api/line-incharge/check-username/",
  LINE_INCHARGE_CHECK_LINE: "/api/line-incharge/check-line/",

  UPLOAD_DOC: "/api/s3-bucket/upload-docs/",
  WAREHOUSE_SKUDETAILS: "/api/outboundWarehouse",
  WAREHOUSE_PRODUCTION_DETAILS: "/api/inboundWarehouse",
};

export default ENDPOINTS;
