import { useQuery, useMutation } from "react-query";
import axiosInstance from "../../service/axiosInstance";
import ENDPOINTS from "../../service/endpoint";

const getProductionIncharge = async (params) => {
  const { data } = await axiosInstance({
    method: "get",
    url: ENDPOINTS.PRODUCTION_INCHARGE_LIST,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useGetProductionIncharge = () => {
  return useMutation({
    queryKey: ["useGetProductionIncharge"],
    mutationFn: (params) => getProductionIncharge(params),
  });
};

const deleteProductionIncharge = async (userId, id) => {
   
    const { data } = await axiosInstance({
      method: "delete",
      url: `${ENDPOINTS.PRODUCTION_INCHARGE_LIST}${userId}/${id}`,
      headers: { "Content-Type": "application/json" },
    });
    return data;
  };
  
  const useDeleteProductionIncharge = () => {
    return useMutation({
      queryKey: ["useDeleteProductionIncharge"],
      mutationFn: ({userId, id}) => deleteProductionIncharge(userId, id),
    });
  };
  

  const addProductionIncharge = async (params) => {
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.PRODUCTION_INCHARGE_ADD,
      data: params.payload,
      headers: { "Content-Type": "application/json" },

    });
    return data;
  };
  
  const useAddProductionIncharge = () => {
    return useMutation({
      queryKey: ["useAddProductionIncharge"],
      mutationFn: (params) => addProductionIncharge(params),
    });
  };

  const editProductionIncharge = async (payload, userId, Id) => {
    console.log("🚀 ~ editProductionIncharge ~ Id:", Id)
    console.log("🚀 ~ editProductionIncharge ~ userId:", userId)
    console.log("🚀 ~ editProductionIncharge ~ payload:", payload)
    const { data } = await axiosInstance({
      method: "put",
      url: `${ENDPOINTS.PRODUCTION_INCHARGE_EDIT}${userId}/${Id}`,
      data: payload,
      headers: { "Content-Type": "application/json" },

    });
    return data;
  };
  
  const useEditProductionIncharge = () => {
    return useMutation({
      queryKey: ["useEditProductionIncharge"],
      mutationFn: ({payload, userId, Id}) => editProductionIncharge(payload, userId, Id),
    });
  };
export { useGetProductionIncharge,
    useDeleteProductionIncharge,
    useAddProductionIncharge,
    useEditProductionIncharge
 };
