import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { useDeleteRawMaterial, useRawMaterialList } from './hooks/rawmaterial'
import { useState } from 'react'
import { toast } from 'react-toastify'

const RawMaterial = () => {
    const navigate =useNavigate()
    const { data, isLoading, mutate, isSuccess, isError, error } = useRawMaterialList();
    const [rawMaterial, setRawMaterial] = useState([])
    const {mutateAsync ,isSuccess:deletesuccess, isError:deleteError, error:deleteerror } = useDeleteRawMaterial()

    const handleDelete = async (product) => {
      if (window.confirm(`Are you sure you want to delete ${product.materialName}?`)) {
        try {
         const response= await mutateAsync({ id: product._id });
          console.log("🚀 ~ handleDelete ~ response:", response)
          toast.success("Deleted Successfully!")
  
          setRawMaterial((prev) => prev.filter((item) => item._id !== product._id));
  
  
        } catch (deleteError) {
          console.error("Failed to delete the production incharge", deleteerror);
          toast.error(deleteerror.response.data.message);
       
        }
      }
    };


    useEffect(() => {
      if (!isSuccess) {
        mutate();
      }

      if (isError) {
        toast.error(error.response.data.message);
        
      }
    }, [isSuccess, isError]);

    useEffect(() => {
      if (data) {
        setRawMaterial(data?.data);
      }
    }, [data]);


    const handleEdit=(product)=>{
      navigate(`/rawmaterial/editrm/${product._id}`)
    }
   
  return (
    <>
    {isLoading ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', width: '80vw' }}>
        <ClipLoader size={100} />
      </div>
    ) : (
    <div className="m-9">
    <div className='piheader' style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
    <h2 className="text-3xl font-extrabold tracking-tight text-slate-900 mb-7">Stock Keeping Unit (SKU)</h2>

      <button
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto mb-7 px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        onClick={() => navigate('/rawmaterial/addrm')} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
      >
        Add SKU
      </button>
    </div>

    
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              S.no
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              SKU Name
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Colour
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Inventory
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Planned Inventory
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Available Inventory

            </th>
            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Edit</span>
            </th>
            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Delete</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {rawMaterial?.map((raw, index) => (
            <tr key={index} data-tooltip-id={`tooltip-${index}`} style={{ background: index % 2 === 0 ? "#fafafa" : "#8fa6ea" }}>
              <td className="px-6 py-3 whitespace-nowrap">{index+1}</td>

              <td className="px-6 py-3 whitespace-nowrap">{raw?.materialName}</td>
              <td className="px-6 py-3 whitespace-nowrap">{raw.variantName}</td>

              <td className="px-6 py-3 whitespace-nowrap">{raw?.quantity}</td>
              <td className="px-6 py-3 whitespace-nowrap">Planned Inventory</td>

              <td className="px-6 py-3 whitespace-nowrap">Available Inventory</td>

              <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                <button className="text-blue-600 hover:text-blue-900" 
                  
                  onClick={()=>{
                    handleEdit(raw)
                  }}
                >
                  Edit
                </button>
              </td>
              <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                <button className="text-red-600 hover:text-red-900"
                  
                  onClick={()=>{
                    handleDelete(raw)
                  }}
                >
                  Delete
                </button>
              </td>
              
            </tr>
          ))}
        </tbody>
      </table>
    
  </div>
  )} 
  </>
  )
}

export default RawMaterial