import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import CreateableSelectWithDelete from "../../components/CreateableSelectWithDelete";
import { format } from "date-fns";
import ENDPOINTS from "../../service/endpoint";
import { toast } from "react-toastify";
import Cookies from 'js-cookie'; 
import axiosInstance from '../../service/axiosInstance'; 

const AddOrder = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = Cookies.get('accessToken');

  const navigate = useNavigate();

  const [factoryProductName, setFactory] = useState("");
  const [promisedStartDate, setStart] = useState("");
  const [deadline, setDeadline] = useState("");

  const [variants, setVariants] = useState([{ name: "", quantity: "", shipped: "0" }]);

  const [totalQuantity, setTotalQuantity] = useState("");
  const [shippedQuantity, setShippedQuantity] = useState("0");
  const [status, setStatus] = useState("Not Started");

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [factoryProducts, setFactoryProducts] = useState([]);
  const [selectedFactoryProduct, setSelectedFactoryProduct] = useState(null);

  const [customerProducts, setCustomerProducts] = useState([]);
  const [selectedCustomerProduct, setSelectedCustomerProduct] = useState(null);

  useEffect(() => {
    const fetchProductsAndCustomers = async () => {
      try {
        const [productResponse, customerResponse, customerProductNameResponse] =
          await Promise.all([
            axiosInstance.get(`${baseURL}${ENDPOINTS.PRODUCT_LIST}`),
            axiosInstance.get(`${baseURL}${ENDPOINTS.CUSTOMER_LIST}`),
            axiosInstance.get(`${baseURL}${ENDPOINTS.CUSTOMER_PRODUCT_NAMES}`),
          ]);
        setFactoryProducts(productResponse?.data?.data);
        setCustomers(customerResponse?.data?.data);
        setCustomerProducts(customerProductNameResponse?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
       
      }
    };

    fetchProductsAndCustomers();
  }, [baseURL, navigate]);

  useEffect(() => {
    const calculateTotalQuantity = () => {
      const total = variants.reduce(
        (sum, variant) => sum + (Number(variant.quantity) || 0),
        0
      );
      setTotalQuantity(total);
    };

    calculateTotalQuantity();
  }, [variants]);

  // Product Variant
  const handleVariantNameChange = (index, selectedOption) => {
    const updatedVariants = [...variants];
    updatedVariants[index].name = selectedOption?.label;
    setVariants(updatedVariants);
  };
  const handleAddVariant = () => {
    setVariants([...variants, { name: "", quantity: "", shipped: "0" }]);
  };
  const handleRemoveVariant = (index) => {
    const updatedVariants = [...variants];
    updatedVariants.splice(index, 1);
    setVariants(updatedVariants);
  };
  const handleVariantQuantityChange = (index, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index].quantity = value;
    setVariants(updatedVariants);
  };

  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  const handleFactoryProductChange = (selectedOption) => {
    setSelectedFactoryProduct(selectedOption);
  };

  const handleCustomerProductChange = (selectedOption) => {
    setSelectedCustomerProduct(selectedOption);
  };

  const handleCreateOption = async (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setCustomerProducts([...customerProducts, newOption]);
    setSelectedCustomerProduct(newOption);

    try {
      const response = await axiosInstance.post(`${baseURL}${ENDPOINTS.CUSTOMER_PRODUCT_NAMES}`, {
        productName: inputValue,
      });
    } catch (error) {
      console.error("Error adding new customer product:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (new Date(promisedStartDate) > new Date(deadline)) {
      alert("Promised start date cannot surpass deadline!");
      return;
    }

    try {
      const payload = {
        customerId: selectedCustomer.value,
        customerProductName: selectedCustomerProduct?.value
          ? selectedCustomerProduct?.value
          : selectedFactoryProduct.value,
        factoryProductName: selectedFactoryProduct.value,
        promisedStartDate: format(new Date(promisedStartDate), "dd-MM-yyyy"),
        deadline: format(new Date(deadline), "dd-MM-yyyy"),
        productVariant: variants,
        totalQuantity: Number(totalQuantity),
        shippedQuantity,
        status,
      };
      
      const response = await axiosInstance.post(`${baseURL}${ENDPOINTS.ORDER_LIST}`, payload);

      toast.success("Order Added Successfully!");
      navigate("/orders");
    } catch (error) {
      console.error("Error adding order:", error);
      toast.error("Failed to add order. Please try again.");
      
    }
  };


  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter' && index === variants?.length - 1) {
      event.preventDefault();
      handleAddVariant();
    }
  };

  return (
    <div>
      <div className="mx-3">
        <button
          type="button"
          onClick={() => {
            navigate("/orders");
          }}
          className="ml-8 w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto"
        >
          <svg
            className="w-5 h-5 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>
          <span>Go back</span>
        </button>
      </div>

      <div className="px-40 py-4">
        <p className="text-2xl font-bold mb-4 text-gray-900">Add Order</p>

        <form onSubmit={handleSubmit}>
          <div className="d-flex gap-6">
            <div
              className="col-span-2 mb-3"
              style={{ width: "calc(100% - 40px)" }}
            >
              <label
                htmlFor="customer_name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Customer Name
              </label>
              <Select
                id="customer_name"
                options={customers.map((customer) => ({
                  value: customer._id,
                  label: customer.name,
                }))}
                // options={[selfProductionOption, ...customerOptions]}
                value={selectedCustomer}
                onChange={handleCustomerChange}
                placeholder="Select a customer..."
                isSearchable
              />

              {/* <option value="selfproduction">Self Production</option>
                {customers?.map((customer) => { 
                  return(
                    <option key={customer?._id} value={customer?._id}>
                    {customer?.name}
                  </option>
                  
                  // value: customer._id, label: customer.name 
                  
                 ) })}

                </Select> */}
              {/* /> */}
            </div>
            <div
              className="grid gap-6 mb-3 md:grid-cols-2"
              style={{ width: "calc(100% - 40px)" }}
            >
              <div>
                <label
                  htmlFor="customer_product_name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Customer Product Name
                </label>
                <CreatableSelect
                  id="customer_product_name"
                  options={customerProducts.map((product) => ({
                    value: product.productName,
                    label: product.productName,
                  }))}
                  value={selectedCustomerProduct}
                  onChange={handleCustomerProductChange}
                  onCreateOption={handleCreateOption}
                  placeholder="Select or type a customer product name"
                  isSearchable
                  isDisabled={selectedCustomer?.label === "self production"} // Disable if "Self Production" is selected
                />
              </div>

              <div>
                <label
                  htmlFor="factory_product_name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Factory Product Name
                </label>
                <Select
                  id="factory_product_name"
                  options={factoryProducts.map((product) => ({
                    value: product.productName,
                    label: product.productName,
                  }))}
                  value={selectedFactoryProduct}
                  onChange={handleFactoryProductChange}
                  placeholder="Select a product..."
                  isSearchable
                />
              </div>
            </div>
            <div
              className="grid gap-6 mb-3 md:grid-cols-2"
              style={{ width: "calc(100% - 40px)" }}
            >
              <div>
                <label
                  htmlFor="promised_start_date"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  {selectedCustomer?.label === "self production"
                    ? "start date"
                    : "Promised Start Date"}
                </label>
                <input
                  value={promisedStartDate}
                  onChange={(e) => {
                    setStart(e.target.value);
                  }}
                  type="date"
                  id="promised_start_date"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder=""
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="deadline"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  {selectedCustomer?.label === "self production"
                    ? "end date"
                    : "Promised Deadline"}
                </label>
                <input
                  value={deadline}
                  onChange={(e) => {
                    setDeadline(e.target.value);
                  }}
                  type="date"
                  id="deadline"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder=""
                  required
                />
              </div>
            </div>

            <div className="mt-2 col-span-2">
              <div
                className="grid grid-cols-2"
                style={{ width: "calc(100% - 40px)" }}
              >
                <label htmlFor="variantName" className="mb-1">
                  Colour
                </label>
                <label htmlFor="variantQuantity" className="mb-1">
                  Quantity
                </label>
              </div>

              {variants.map((variant, index) => (
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  key={index}
                >
                  <div
                    className="grid grid-cols-2 gap-2 mb-2"
                    style={{ width: "calc(100% - 40px)" }}
                  >
                    <CreateableSelectWithDelete
                      getArticlesUrl={`${baseURL}${ENDPOINTS.VARIANT_NAME_LIST}`}
                      deleteArticleUrl={`${baseURL}${ENDPOINTS.VARIANT_NAME_DELETE}`}
                      addArticleUrl={`${baseURL}${ENDPOINTS.ADD_VARIANT_NAME}`}
                      token={token}
                      keyProp="variantName"
                      value={variant.name}
                      // value={material.materialName}
                      onChange={(selectedOption) =>
                        handleVariantNameChange(index, selectedOption)
                      }
                      // onChange={(option) => setFieldValue(`rawMaterial[${index}].materialName`, option.label)}
                      placeholder="Select Variant Name..."
                    />

                    <div className="flex">
                      <input
                        value={variant.quantity}
                        onChange={(e) => {
                          handleVariantQuantityChange(index, e.target.value);
                        }}
                        type="number"
                        id="variantQuantity"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder=""
                        onKeyDown={(event) => handleKeyDown(event, index)}
                        required
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "40px",
                    }}
                  >
                    {variants.length > 1 && (
                      <button
                        onClick={() => handleRemoveVariant(index)}
                        type="button"
                        className="m-2 border-2 border-gray-700 hover:bg-slate-200 hover:text-white font-medium rounded-full text-sm p-1 w-6 h-6"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13"
                          height="13"
                          viewBox="0 0 24 24"
                        >
                          <path fillRule="evenodd" d="M2 11h20v2H2z"></path>
                        </svg>
                      </button>
                    )}
                    {index === variants?.length - 1 && (
                      <button
                        onClick={handleAddVariant}
                        type="button"
                        className="m-2 border-2 border-gray-700 hover:bg-slate-200 hover:text-white font-medium rounded-full text-sm p-1 w-6 h-6"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13"
                          height="13"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
                          ></path>
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div
              className="grid gap-6 mb-3 md:grid-cols-2"
              style={{ width: "calc(100% - 40px)" }}
            >
              <div>
                <label
                  htmlFor="totalQuantity"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Total Quantity
                </label>
                <input
                  value={totalQuantity}
                  onChange={(e) => {
                    setTotalQuantity(e.target.value);
                  }}
                  type="number"
                  id="totalQuantity"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder=""
                  required
                />
              </div>
            </div>

            {/* <div>
              <label htmlFor="shippedQuantity" className="block mb-2 text-sm font-medium text-gray-600">Shipped Quantity</label>
              <input value={shippedQuantity} type="number" id="shippedQuantity" className="bg-gray-50 text-gray-400 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" disabled/>
            </div> */}

            {/* <div>
              <label htmlFor="status" className="block mb-1 text-sm font-medium text-gray-600">
                  Status
              </label>
              <select
                  id="status"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  value={status}
                  disabled
              >
                  <option value="">Select Status...</option>
                  <option value="Not Started">Not Started</option>
                  <option value="In Production">In Production</option>
              </select>                
            </div> */}
          </div>

          <div className=" flex justify-center">
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            >
              Create Order
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOrder;
