import { useQuery, useMutation } from "react-query";
import axiosInstance from "../../service/axiosInstance";
import ENDPOINTS from "../../service/endpoint";

const getLineIncharge = async (params) => {
  const { data } = await axiosInstance({
    method: "get",
    url: ENDPOINTS.LINE_INCHARGE_LIST,
    headers: { "Content-Type": "application/json" },
  });
  console.log(data);
  return data;
};
const useGetLineIncharge = () => {
  return useMutation({
    queryKey: ["useGetLineIncharge"],
    mutationFn: (params) => getLineIncharge(params),
  });
};

 
 const addLineIncharge = async (params) => {
  const { data } = await axiosInstance({
    method: "post",
    url: ENDPOINTS.ADD_LINE_INCHARGE,
    headers: { "Content-Type": "application/json" },
    data: params,
  });
  return data;
};
const useAddLineIncharge = () => {
  return useMutation({
    queryKey: ["useAddLineIncharge"],
    mutationFn: (params) => addLineIncharge(params),
  });
};  

const editLineIncharge = async (params) => {
  const { data } = await axiosInstance({
    method: "put",
    url: `${ENDPOINTS.EDIT_LINE_INCHARGE}${params?.id?.id}`,
    headers: { "Content-Type": "application/json" },
    data: params.lineIncharge,
  });
  return data;
};
const useEditLineIncharge = () => {
  return useMutation({
    queryKey: ["useEditLineIncharge"],
    mutationFn: (params) => editLineIncharge(params),
  }); 
};  

const deleteLineIncharge = async (params) => {
  const { data } = await axiosInstance({
    method: "delete",
    url: `${ENDPOINTS.DELETE_LINE_INCHARGE}${params?.id?.id}`,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};
const useDeleteLineIncharge = () => {
  return useMutation({
    queryKey: ["useDeleteLineIncharge"],
    mutationFn: (params) => deleteLineIncharge(params),
  });
};

const checkUsername = async (params) => {
  const { data } = await axiosInstance({
    method: "post",
    url: ENDPOINTS.CHECK_USERNAME,
    headers: { "Content-Type": "application/json" },
    data: params,
  });
  return data;
};
const useCheckUsername = () => {
  return useMutation({
    queryKey: ["useCheckUsername"],
    mutationFn: (params) => checkUsername(params),
  });
};

const checkLine = async (params) => {
  const { data } = await axiosInstance({
    method: "post",
    url: ENDPOINTS.CHECK_LINE,
    headers: { "Content-Type": "application/json" },
    data: params,
  });
  return data;
};
const useCheckLine = () => {
  return useMutation({
    queryKey: ["useCheckLine"],
    mutationFn: (params) => checkLine(params),
  });
};

export { useGetLineIncharge, useAddLineIncharge, useEditLineIncharge, useDeleteLineIncharge, useCheckUsername, useCheckLine };

