import React, { useState } from 'react';
import PropTypes from 'prop-types';


const Accordian = ({ titleItems, children,  isOpen, onClick  }) => {
  

  return (
    <div className="border border-gray-200 rounded mb-2">
      <button
        className="w-full text-left p-4 bg-gray-100 hover:bg-gray-200 focus:outline-none"
        onClick={onClick}
      >
        <div className="flex justify-between items-center ">
          {titleItems.map((item, index) => (
            <span key={index} className="flex-grow text-center w-1/5">
              {item}
            </span>
          ))}
          <svg
            className={`w-5 h-5 transform transition-transform ${
              isOpen ? 'rotate-180' : ''
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </div>
      </button>
      {isOpen  && <div className="p-4 bg-white">{children}</div>}
    </div>
  );
};


Accordian.propTypes = {
    titleItems: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  };
export default Accordian;
