import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import PropTypes from 'prop-types';
import { UserProfile } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import { useEmployeeListById } from '../pages/hooks/login';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';


const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full mt-2 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = ({setTokens}) => {
  const navigate = useNavigate()
  const profileId=localStorage.getItem('user_Id')
  const { currentColor, activeMenu, setActiveMenu, isClicked, setScreenSize, screenSize, setIsClicked } = useStateContext();
  const userProfileRef = useRef(null);
  const profileTriggerRef = useRef(null);
  const [showProfile, setShowProfile] = useState(false);
  const [userProfileDetails, setUserProfileDetails] = useState('')
  const { data, isLoading, mutate, isSuccess, isError, error } = useEmployeeListById();

  useEffect(() => {
    if (!isSuccess) {
      mutate({params:profileId});
    }

    if (isError) {
      toast.error(error?.response?.data?.message);
    }
  }, [isSuccess, isError, profileId]);

  useEffect(() => {
    if (data) {
      setUserProfileDetails(data?.data);
    }
  }, [data]);

  const handleClick = () => {
    setShowProfile(!showProfile);
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        userProfileRef.current &&
        !userProfileRef.current.contains(event.target) &&
        profileTriggerRef.current &&
        !profileTriggerRef.current.contains(event.target)
      ) {
        setShowProfile(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsClicked]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
    <div className="grid p-2 md:ml-6 md:mr-6">
      {!activeMenu && <NavButton title="Menu" customFunc={handleActiveMenu} color={currentColor} icon={<AiOutlineMenu />} />}
      
      <span className="inline justify-self-end">
        <TooltipComponent content="Profile" position="BottomCenter">
          {isLoading ?(
            <div>
              <ClipLoader size={20}/>
            </div>
          ):(
          <span
            ref={profileTriggerRef}
            className="flex gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
            onClick={(e) => {
              e.preventDefault();
              handleClick();
            }}
          >
            {/* <img
              className="rounded-full w-8 h-8"
              src="https://i.postimg.cc/QtvWCgyQ/1685720568798.jpg"
              alt="user-profile"
            /> */}
            <p>
              <span className="text-gray-400 text-14">Hi,</span>{' '}
              <span className="text-gray-400 font-bold ml-1 text-14">
                {userProfileDetails?.name}
              </span>
            </p>
            <MdKeyboardArrowDown className="text-gray-400 text-14" />
          </span>

          )}
        </TooltipComponent>

        {showProfile && <UserProfile ref={userProfileRef} setShowProfile={setShowProfile} userProfileDetails={userProfileDetails} setTokens={setTokens} />}
      </span>
    </div>
  );
};

// PropTypes validation for each prop
NavButton.propTypes = {
  title: PropTypes.string.isRequired,
  customFunc: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  dotColor: PropTypes.string,
};
Navbar.propTypes = {
  setTokens: PropTypes.func.isRequired,
};

export default Navbar;
