import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import axios from 'axios';
import axiosInstance from '../service/axiosInstance'; // Import the axiosInstance

const CustomOption = (props) => {
  const { data, innerRef, innerProps, handleDelete } = props;

  return (
    <div ref={innerRef} {...innerProps} className="flex justify-between items-center p-2">
      {data.label}
      <button type='button'
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(data);
        }}
        className="text-red-500 ml-2"
      >
        x
      </button>
    </div>
  );
};

CustomOption.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
  innerRef: PropTypes.func.isRequired,
  innerProps: PropTypes.object.isRequired,
};

const CreateableSelectWithDelete = ({ getArticlesUrl, deleteArticleUrl, addArticleUrl, token, keyProp, value, onChange }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(value);

  useEffect(() => {
    if (value) {
      setSelectedOption(options.find((option) => option.label === value));
    } else {
      setSelectedOption(null);
    }
  }, [value, options]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(getArticlesUrl, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        // const response = await axiosInstance.get(getArticlesUrl);

        setOptions(response?.data?.data?.map(object => ({ value: object._id, label: object[keyProp] })));
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };
    fetchArticles();
  }, [getArticlesUrl, token, keyProp]);

  const handleCreate = async (inputValue) => {
    try {
      const response = await axios.post(addArticleUrl, { [keyProp]: inputValue }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const newOption = keyProp === 'category'
        ? { value: response.data._id, label: inputValue }
        : { value: inputValue, label: inputValue };
      setOptions((prev) => [...prev, newOption]);
      setSelectedOption(newOption);
      onChange(newOption);
    } catch (error) {
      console.error('Error adding option:', error);
    }
  };

  const handleDelete = async (optionToDelete) => {
    try {
      await axios.delete(`${deleteArticleUrl}/${optionToDelete.value}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setOptions((prev) => prev.filter((option) => option.value !== optionToDelete.value));
      if (selectedOption && selectedOption.value === optionToDelete.value) {
        setSelectedOption(null);
        onChange(null);
      }
    } catch (error) {
      console.error('Error deleting option:', error);
    }
  };

  return (
    <div>
      <CreatableSelect
        isClearable
        value={selectedOption}
        options={options}
        onChange={(option) => {
          setSelectedOption(option);
          onChange(option);
        }}
        onCreateOption={handleCreate}
        components={{ Option: (props) => <CustomOption {...props} handleDelete={handleDelete} /> }}
        styles={{
          option: (base) => ({
            ...base,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }),
        }}
      />
    </div>
  );
};

CreateableSelectWithDelete.propTypes = {
  getArticlesUrl: PropTypes.string.isRequired,
  deleteArticleUrl: PropTypes.string.isRequired,
  addArticleUrl: PropTypes.string.isRequired,
  token: PropTypes.string,
  keyProp: PropTypes.string.isRequired,
  value: PropTypes.object, // Updated to accept an object
  onChange: PropTypes.func.isRequired,
};

export default CreateableSelectWithDelete;
