import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import PropTypes from "prop-types";
import "../styles/mycalender.css";
import CalenderPop from "./CalenderPop";

const localizer = momentLocalizer(moment);

const MyCalendar = ({ events: initialEvents, onDeleteEvent }) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const formattedEvents = initialEvents?.map((event) => ({
      id: event?._id,
      title: `${event?.orderName} - ${event?.AssemblyLineName}`,
      actualStartDate: event?.actualStartDate,
      projectedEndDate: event?.projectedEndDate,
      start: new Date(event?.actualStartDate),
      end: new Date(event?.projectedEndDate),
      assemblyLineLineName: event?.AssemblyLineName,
      assignAssemblyLineId: event?.assignAssemblyLineId,
      averageDailyProduction: event?.averageDailyProduction,
      customerId: event?.customerId,
      customerName: event?.customerName,
      factoryProductName: event?.factoryProductName,
      orderId: event?.orderId,
      orderName: event?.orderName,
      promiseEndDate: event?.promiseEndDate,
      promiseStartDate: event?.promiseStartDate,
      productOutputQuantity: event?.productOutputQuantity,
      totalQuantity: event?.totalQuantity,
    }));
    setEvents(formattedEvents);
  }, [initialEvents]);

  const eventStyleGetter = (event) => {
    const backgroundColors = {
      "Assembly Line 1": "red",
      "Assembly Line 2": "blue",
      "Assembly Line 3": "green",
      "Assembly Line 4": "orange",
    };
    const borderColor = backgroundColors[event.assemblyLineLineName] || "gray";
    return {
      style: {
        backgroundColor: backgroundColors[event.assemblyLineLineName] || "gray",
        borderRadius: "0px",
        border: `1px solid ${borderColor}`,
        color: "white",
        cursor: "pointer",
      },
    };
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  };

  const closeModal = () => {
    setSelectedEvent(null);
  };

  const handleEventDeleted = (id) => {
    onDeleteEvent(id);
    closeModal();
  };

  return (
    <div>
      <h1
        style={{
          marginBottom: "10px",
          fontSize: "20px",
          fontWeight: "700",
          textDecoration: "underline",
        }}
      >
        Production Plan
      </h1>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600 }}
        selectable
        onSelectEvent={handleSelectEvent}
        eventPropGetter={eventStyleGetter}
      />
      {selectedEvent && (
        <CalenderPop selectedEvent={selectedEvent} closeModal={closeModal} onDelete={handleEventDeleted} />
      )}
    </div>
  );
};

MyCalendar.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      orderName: PropTypes.string.isRequired,
      AssemblyLineName: PropTypes.string.isRequired,
      actualStartDate: PropTypes.string.isRequired,
      projectedEndDate: PropTypes.string.isRequired,
      assignAssemblyLineId: PropTypes.string.isRequired,
      averageDailyProduction: PropTypes.string.isRequired,
      customerId: PropTypes.string.isRequired,
      customerName: PropTypes.string.isRequired,
      factoryProductName: PropTypes.string.isRequired,
      orderId: PropTypes.string.isRequired,
      promiseEndDate: PropTypes.string.isRequired,
      promiseStartDate: PropTypes.string.isRequired,
      productOutputQuantity: PropTypes.string.isRequired,
      totalQuantity: PropTypes.string.isRequired,
    })
  ).isRequired,
  onDeleteEvent: PropTypes.func.isRequired,
};

export default MyCalendar;
