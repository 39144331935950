import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'; // Import PropTypes
import axios from 'axios'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEmployeeList } from './hooks/login';
import { ClipLoader } from 'react-spinners';
import { useSingleAssemblyLine } from './hooks/assemblyline';

const AssemblyLineStationView = ({}) => {
    const id =useParams();
    const {data, mutate, isLoading, isError, error} =useSingleAssemblyLine()
    const navigate = useNavigate()
    const {data:employees , error:employeeError } =useEmployeeList()

    const [assemblyLineData, setAssemblyLineData] = useState('')
    const [employeesList, setEmployeesList] = useState([])
    useEffect(() => {
      mutate({
        id:id?.id
      })
    
      
    }, [id, mutate])
    

    useEffect(() => {
        if ( employeeError || isError) {
            navigate('/') // Replace '/error-page' with your desired error route
        } else {
            setAssemblyLineData(data?.data);
            setEmployeesList(employees?.data)
        }


     
    }, [data, employees, employeeError, navigate ])
   

    

  return (
   <div>

    {isLoading ?(
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'80vh'}}>

            <ClipLoader size={100}/>
        </div>
    ):(
    <>
        <div className="mx-3 mt-5 mb-5">
        <button
          type="button"
          onClick={() => {
            navigate("/line");
          }}
          className="ml-8 mt-3 w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
        >
          <svg
            className="w-5 h-5 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>
          <span>Go back</span>
        </button>
      </div>
  
            <div >
                <div style={{display:'flex', marginLeft:'10%', gap:'20px'}}>
                    <h3 style={{fontSize:'18px', fontWeight:'500'}}>Line Incharge: <span style={{fontSize:'20px', fontWeight:'600'}}>{assemblyLineData?.lineIncharge} </span> </h3>
                    <p style={{fontSize:'18px', fontWeight:'500'}}>Line Name: <span style={{fontSize:'20px', fontWeight:'600'}}>{assemblyLineData?.lineName}</span></p>
                    <p style={{fontSize:'18px', fontWeight:'500'}}>Product : <span style={{fontSize:'20px', fontWeight:'600'}}>{assemblyLineData?.product}</span></p>
                </div>
{assemblyLineData?.stations?.map((item, index)=>{
        return(

       
    <div className="flex items-center justify-center space-x-4 my-4" key={index}>

           
<div style={{width:'35%'}}>
        {item?.employees?.slice(0,2).map((emp, indexs)=>{
         const employee =employeesList && employeesList?.find(employee => employee._id === emp.employee);

            return(
            <div className="flex gap-2 items-center" key={indexs} style={{justifyContent:'flex-end', alignContent:'center'}} >
                <div style={{display:'flex', justifyContent:'space-between', width:'90%'}}>
                <span className='bg-amber-300 rounded-full p-1' style={{padding:'3px 10px'}}>{emp?.task}</span>
                <span className='bg-blue-400 rounded-full p-1' style={{padding:'3px 10px'}}>{employee ? employee.name : ''}</span>

                </div>
                <span><button  className="bg-slate-600 w-8 h-8 rounded-full "></button></span>
            </div>
            
            )
})}
        </div>


        <div className="flex flex-col items-center border-t border-b border-gray-500 px-4">
             <span className='my-5'>{item?.name}</span>
        </div> 
        <div style={{width:'35%'}}>
        {item?.employees?.slice(2,4).map((emps, indexes)=>{
            const employee =employeesList && employeesList?.find(employee => employee._id === emps.employee);

            return(
            <div className="flex gap-2 items-center" key={indexes} style={{justifyContent:'flex-start', alignContent:'center'}}>
                <span><button  className="bg-slate-600 w-8 h-8 rounded-full "></button></span>
                <div style={{display:'flex', justifyContent:'space-between', width:'90%'}}>

                <span className='bg-blue-400 rounded-full p-1' style={{padding:'3px 10px'}}>{employee ? employee.name : ''}</span>
                <span className='bg-amber-300 rounded-full p-1' style={{padding:'3px 10px'}}>{emps?.task}</span>
                </div>
            </div>
            )})}
           
        </div>
    </div>

)
})}

            </div>

            </>

)}
   </div> 
  )
}


// PropTypes validation for stationNumber prop
// AssemblyLine.propTypes = {
//     stationNumber: PropTypes.number.isRequired,
//   };
  
export default AssemblyLineStationView

