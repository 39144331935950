import React, { forwardRef } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { Button } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import Cookies from 'js-cookie';



const UserProfile = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const { setShowProfile, userProfileDetails ,setTokens } = props;
  const { currentColor } = useStateContext();
  const handleClick =()=>{
    setShowProfile(false)
  }
  
  const handleLogout = () => {
    setShowProfile(false);

    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');

    localStorage.removeItem('user_Id');
    
    setTokens(null);
    navigate('/');
  };

  return (
    <div ref={ref} className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <button style={{color:'rgb(153, 171, 180)'}} onClick={handleClick}
        >
          <MdOutlineCancel />
        </button>
        {/* <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
          onClick={handleClick}
        /> */}
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        {/* <img
          className="rounded-full h-24 w-24"
          src="https://i.postimg.cc/QtvWCgyQ/1685720568798.jpg"
          alt="user-profile"
        /> */}
        <div>
          <p className="font-semibold text-xl dark:text-gray-200"> {userProfileDetails?.name} </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">  Administrator   </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400"> {userProfileDetails?.email}</p>
        </div>
      </div>
      <div className="mt-5">
        <Button
          color="white"
          bgColor={currentColor}
          text="Logout"
          borderRadius="10px"
          width="full"
          onClick={handleLogout}
        />
      </div>
    </div>
  );
});

// Set the display name for the UserProfile component
UserProfile.displayName = 'UserProfile';
UserProfile.propTypes = {
  setShowProfile: PropTypes.func.isRequired, // Add this line for setShowProfile prop validation
  userProfileDetails:PropTypes.object.isRequired,
  setTokens:PropTypes.object.isRequired,
};

export default UserProfile;
