import {useQuery, useMutation } from "react-query";
import axiosInstance from "../../service/axiosInstance";
import ENDPOINTS from "../../service/endpoint";


const fetchProductionPlan = async (params) => {
    console.log("🚀 ~ fetchProductionPlan ~ params:", params)
      
   
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.ADD_PRODUCTION_PLAN,
      headers: { "Content-Type": "application/json" },
      data: params.payload,
    });
    return data;
  
  };
  
  const useProductionPlan = () => {
    return useMutation({
      
      queryKey: ["useProductionPlan"],
      mutationFn: (params) => fetchProductionPlan(params),
    });
  };

  const fetchProductionPlanList = async (params) => {
    const { data } = await axiosInstance({
      method: "get",
      url: ENDPOINTS.PRODUCTION_PLAN_LIST,
      headers: { "Content-Type": "application/json" },
    });
    return data;
  };
  
  const useProductionPlanList = () => {
    return useMutation({
      
      queryKey: ["useProductionPlanList"],
      mutationFn: (params) => fetchProductionPlanList(params),
    });
  };

  
  const fetchSingleProductionPlan = async (params) => {
    console.log("🚀 ~ fetchProductionPlan ~ params:", params)
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.SINGLE_PRODUCTION_PLAN,
      headers: { "Content-Type": "application/json" },
      data: params,
    });
    return data;
  };
  
  const useSingleProductionPlan = () => {
    return useMutation({
      
      queryKey: ["useProductionPlan"],
      mutationFn: (params) => fetchSingleProductionPlan(params),
    });
  };


  // edit production plan

  const editProductionPlan = async (params) => {
    console.log("🚀 ~ fetchProductionPlan ~ params:", params)
    const { data } = await axiosInstance({
      method: "put",
      url: `${ENDPOINTS.EDIT_PRODUCTION_PLAN}${params?.id?.id}`,
      headers: { "Content-Type": "application/json" },
      data: params.payload,
    });
    return data;
  };
  
  const useEditProductionPlan = () => {
    return useMutation({
      
      queryKey: ["useEditProductionPlan"],
      mutationFn: (params) => editProductionPlan(params),
    });
  };


  // delete production plan

  const deleteProductionPlan = async (id) => {
   
    const { data } = await axiosInstance({
      method: "delete",
      url: `${ENDPOINTS.DELETE_PRODUCTION_PLAN}${id}`,
      headers: { "Content-Type": "application/json" },
    });
    return data;
  };
  
  const useDeleteProductionPlan = () => {
    return useMutation({
      queryKey: ["useDeleteProductionPlan"],
      mutationFn: ({id}) => deleteProductionPlan(id),
    });
  };

  export { useProductionPlan,
    useProductionPlanList,
    useSingleProductionPlan,
    useEditProductionPlan,
    useDeleteProductionPlan,
   };
