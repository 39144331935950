import { useMutation, useQuery } from "react-query";
import axiosInstance from "../../service/axiosInstance";
import ENDPOINTS from "../../service/endpoint";

const fetchSkuDetails = async (params) => {
    const { data } = await axiosInstance({
      method: "get",
      url: ENDPOINTS.WAREHOUSE_SKUDETAILS,
      headers: { "Content-Type": "application/json" },
    });
    return data;
  };
  
  const useSkuDetails = () => {
    return useQuery({
      queryKey: ["useSkuDetails"],
      queryFn: (params) => fetchSkuDetails(params),
    });
  };


  // change stu status

  const updateSkuStatus = async (params) => {
    console.log("🚀 ~ fetchProductionPlan ~ params:", params)
    const { data } = await axiosInstance({
      method: "put",
      url: `${ENDPOINTS.WAREHOUSE_SKUDETAILS}/status/${params?.id}`,
      headers: { "Content-Type": "application/json" },
      data: params?.status,
    });
    return data;
  };
  
  const useSKUStatus = () => {
    return useMutation({
      
      queryKey: ["useSKUStatus"],
      mutationFn: (params) => updateSkuStatus(params),
    });
  };

  const fetchProductionDetails = async (params) => {
    const { data } = await axiosInstance({
      method: "get",
      url: ENDPOINTS.WAREHOUSE_PRODUCTION_DETAILS,
      headers: { "Content-Type": "application/json" },
    });
    return data;
  };
  
  const useProductionDetails = () => {
    return useQuery({
      queryKey: ["useProductionDetails"],
      queryFn: (params) => fetchProductionDetails(params),
    });
  };

  // update  production status

  const updateProductionStatus = async (params) => {
    console.log("🚀 ~ fetchProductionPlan ~ params:", params)
    const { data } = await axiosInstance({
      method: "put",
      url: `${ENDPOINTS.WAREHOUSE_PRODUCTION_DETAILS}/status/${params?.id}`,
      headers: { "Content-Type": "application/json" },
      data: params?.status,
    });
    return data;
  };
  
  const useProductionStatus = () => {
    return useMutation({
      
      queryKey: ["useProductionStatus"],
      mutationFn: (params) => updateProductionStatus(params),
    });
  };


  export{
    useSkuDetails,
    useProductionDetails,
    useSKUStatus,
    useProductionStatus,
  }