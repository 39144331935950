import Cookies from "js-cookie";

export function capitalizeFirstLetter(str) {
  // Check if the input is a valid string
  if (typeof str !== "string" || str.length === 0) {
    return str;
  }

  // Capitalize the first letter and concatenate with the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function logout() {
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");
  localStorage.removeItem("user_Id");
}
