import React from 'react';
import PropTypes from 'prop-types';  // Import PropTypes

const Header = ({ category, title }) => (
  <div className="mb-10">
    <p className="text-lg text-gray-400">{category}</p>
    <p className="text-3xl font-extrabold tracking-tight text-slate-900">
      {title}
    </p>
  </div>
);

// PropTypes validation for each prop
Header.propTypes = {
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Header;
