import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCustomerList, useCustomerDelete } from './hooks/customer';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify'
import Loader from '../components/Loader'; // Import the Loader component
import Cookies from 'js-cookie'; // Import js-cookie

const Customers = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = Cookies.get('accessToken');

  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const {data, isLoading, isError, error}  = useCustomerList();   
  const {mutateAsync, isSuccess:deletesuccess, isError:deleteError, error:deleterror}  = useCustomerDelete();   

  useEffect(() => {
    if(data){
      setCustomers(data?.data);
    }
    
    if (isError) {
      toast.error(error.response.data.message);
      
    }
  }, [data, isError, error]);  
 
  const handleDelete = async (userId, customerId, name) => {
    // Ask for confirmation before deleting
    const confirmed = window.confirm(`Are you sure you want to delete this customer: ${name}?`);
    if (!confirmed) {
      return; // If user cancels, do nothing
    }

    try {
      const response= await mutateAsync({ userId: userId, id: customerId });
     
      setCustomers((prev) => prev.filter((item) => item._id !== customerId));
      toast.success("Deleted Successfully!");
    } catch (deleteError) {
      console.error("Failed to delete the customer", deleterror);
      toast.error(deleterror.response.data.message);
     
    }

    // try {
    //   // Filter out the deleted customer from the current state
    //   const updatedCustomers = customers.filter((customer) => customer._id !== customerId);
    //   setCustomers(updatedCustomers);
    // } catch (error) {
    //   console.error('Error deleting customer:', error);
    // }
  };

  return (
    <div className="mx-9 px-9 mt-2">
      <div className="mb-7 flex justify-between">
            <h2 className="text-3xl font-extrabold tracking-tight text-slate-900">Customers</h2>
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              onClick={() => navigate('/customers/addcustomer')}
            >
              Add Customer
            </button>
      </div>

      {isLoading? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', width: '80vw' }}>
          <ClipLoader size={100} />
        </div>
      ) : (
        <div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Customer Name
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Phone Number
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Delete</span>
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
              {customers?.map((customer, index) => (
                <tr key={customer._id} className={index % 2 === 0 ? 'bg-white' : 'bg-indigo-300'}>
                  <td className="px-6 py-4 whitespace-nowrap">{customer.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{customer.phoneNumber}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button className="text-blue-600 hover:text-blue-900" onClick={() => navigate(`/customers/editcustomer/${customer.userId}/${customer._id}`)}>
                      Edit
                    </button>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button className="text-red-600 hover:text-red-900" onClick={() => handleDelete(customer?.userId, customer?._id, customer?.name)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Customers;
