import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Navbar,
  Footer,
  Sidebar,
  CreatableSelectWithDelete,
} from "./components";
import {
  Orders,
  Calendar,
  Customers,
  Kanban,
  ProductMaster,
  ProductionIncharge,
  LineIncharge,
  AssemblyLine,
  AssemblyLineStationView,
  AddOrder,
  OrderDetails,
  Reports,
  EditOrder,
  RawMaterial,
  AddRawMaterial,
  EditRawMaterial,
  LineWorkers,
} from "./pages";
import {
  AddProduct,
  EditProduct,
  AddPI,
  AddLI,
  EditLI,
  AddCustomer,
  EditCustomer,
  AddEmpolyee,
  Warehouse,
  Login,
  EditProductionIncharge,
  ProductionPlan,
  AddProductionPlan,
  EditProductionPlan,
} from "./pages";

import "./index.css";
import "./App.css";

import { useStateContext } from "./contexts/ContextProvider";
import Dashboard from "./pages/Dashboard";
import TabbedView from "./components/TabbedView";
import Graph from "./pages/Graph";
import Cookies from "js-cookie"; // Import js-cookie
import { setupResponseInterceptor } from "./service/axiosInstance";
import { useError } from "./contexts/ErrorProvider";

const App = () => {
  const navigate = useNavigate();
  const { activeMenu } = useStateContext();
  const tokenes = Cookies.get("accessToken");
  const { error, setError } = useError();

  const [tokens, setTokens] = useState(tokenes);

  const baseURL = process.env.REACT_APP_BASE_URL;
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (error) {
      navigate("/");
      // Refresh the page otherwise login form shows but the sidebar, navbar and token errors continue
      navigate(0);
    }
  }, [error, navigate]);

  if (!isLoaded) {
    setIsLoaded(true);
    setupResponseInterceptor(setError);
  }

  return (
    <div>
      <div className="flex relative">
        {tokens &&
          (activeMenu ? (
            <div className="w-72 fixed sidebar bg-white ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0">
              <Sidebar />
            </div>
          ))}
        <div
          style={{ width: !tokens ? "100%" : "" }}
          className={
            tokens &&
            (activeMenu
              ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
              : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 ")
          }
        >
          {tokens && (
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              <Navbar setTokens={setTokens} />
            </div>
          )}
          <div>
            <Routes>
              <Route path="/" element={<Login setTokens={setTokens} />} />
              <Route path="/dashboard" element={<Dashboard />} />

              <Route path="/details" element={<OrderDetails />} />

              <Route path="/orders" element={<Orders />} />
              <Route path="/orders/addorder" element={<AddOrder />} />
              <Route
                path="/orders/editorder/:orderId"
                element={<EditOrder />}
              />

              <Route path="/product" element={<ProductMaster />} />
              <Route path="/product/addproduct" element={<AddProduct />} />
              <Route
                path="/product/editproduct/:productId"
                element={<EditProduct />}
              />

              <Route path="/li" element={<LineIncharge />} />
              <Route path="/li/addli" element={<AddLI />} />
              <Route path="/li/editli/:userId/:id" element={<EditLI />} />

              <Route path="/pi/addpi" element={<AddPI />} />

              <Route path="/customers" element={<Customers />} />
              <Route path="/customers/addcustomer" element={<AddCustomer />} />
              <Route
                path="/customers/editcustomer/:userId/:id"
                element={<EditCustomer />}
              />

              <Route path="/pi" element={<ProductionIncharge />} />
              <Route path="/addpi" element={<AddPI />} />
              <Route path="/editpi" element={<EditProductionIncharge />} />

              <Route
                path="/line"
                element={<AssemblyLine stationNumber={1} />}
              />

              <Route path="/currentordergraph" element={<Graph />} />
              <Route path="/LineWorkers" element={<LineWorkers />} />

              <Route path="" element={<Calendar />} />
              <Route path="/warehouse" element={<Warehouse />} />
              <Route path="/reports" element={<Reports />} />

              <Route path="/addemployee" element={<AddEmpolyee />} />
              <Route path="/tab" element={<TabbedView />} />

              <Route path="/kanban" element={<Kanban />} />
              <Route path="/currentordergraph" element={<Graph />} />
              <Route path="/pi/editpi" element={<EditProductionIncharge />} />
              <Route
                path="/line/asemblylinestation/:id"
                element={<AssemblyLineStationView />}
              />
              <Route path="/plan" element={<ProductionPlan />} />
              <Route
                path="/plan/addproductionplan"
                element={<AddProductionPlan />}
              />
              <Route
                path="/plan/editproductionplan/:id"
                element={<EditProductionPlan />}
              />
              <Route path="/plan/:type/addcustomer" element={<AddCustomer />} />

              <Route path="/rawmaterial" element={<RawMaterial />} />
              <Route path="/rawmaterial/addrm" element={<AddRawMaterial />} />
              <Route
                path="/rawmaterial/editrm/:id"
                element={<EditRawMaterial />}
              />

              {/* Example for using CreatableSelectWithDelete */}
              <Route
                path="/select"
                element={
                  <CreatableSelectWithDelete
                    getArticlesUrl={`${baseURL}/api/admin/orders/customer-product-names`} // fetch api
                    deleteArticleUrl={`${baseURL}/api/admin/orders/customer-product-names`} // delete api
                    addArticleUrl={`${baseURL}/api/admin/orders/customer-product-names`} // add api
                    keyProp="productName" // key of the fetched object whose value you want to show in the options of dropdown
                  />
                }
              />
            </Routes>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default App;
