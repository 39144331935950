import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AOS from "aos"; // Import AOS library
import { toast, } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import PropTypes from 'prop-types'; // Import PropTypes
import { useLogin } from './hooks/login';
import { BeatLoader } from 'react-spinners';
import Cookies from 'js-cookie'; // Import js-cookie

const Login = ({setTokens}) => {
    const baseURL= process.env.REACT_APP_BASE_URL;
    const token = Cookies.get('accessToken');

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const { mutateAsync, isLoading, isSuccess, isError, data , error} = useLogin();

    useEffect(() => {
    if (isSuccess ) {
        if (data?.data?.userType === 'admin') {
            toast.success(data?.message);
            Cookies.set('accessToken', data?.data?.accessToken, { expires: 30 });
            Cookies.set('refreshToken', data?.data?.refreshToken, { expires: 7 });
            localStorage.setItem('user_Id', data?.data?.userId);

            setTokens(data?.data?.accessToken);
            navigate("/dashboard");
        } else {
            toast.error('Invalid User');
        }
    }
    if (isError) {
        toast.error(error?.response?.data?.message);
    }
    }, [isSuccess, isError, data, error, navigate, setTokens]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await mutateAsync({
                username: username,
                password: password,
            });
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    useEffect(() => {
        if (token) {

            navigate("/dashboard");
          
        }
      }, [token]);
      useEffect(() => {
        AOS.init();
      }, []);

    return (
        <div>
            <section className="bg-gray-50">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900">
                        <img className="w-8 h-8 mr-2" src="https://i.postimg.cc/K8QFJGFw/1705389198122.jpg" alt="logo"/>
                        Soluperts
                    </a>

                    <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                Admin Dashboard Login
                            </h1>

                            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                                <div>
                                    <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900">Username</label>
                                    <input
                                        onChange={(e) => setUsername(e.target.value)}
                                        type="text"
                                        name="username"
                                        id="username"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Password</label>
                                    <input
                                        onChange={(e) => setPassword(e.target.value)}
                                        type="password"
                                        name="password"
                                        id="password"
                                        placeholder="••••••••"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                        required
                                    />
                                </div>

                                <button
                                    type="submit"
                                    className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    disabled={isLoading}
                                >{isLoading ? (
                                    <BeatLoader size={8} color={"#ffffff"} />
                                  ) : (
                                    "Login"
                                  )}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

Login.propTypes = {
    setTokens: PropTypes.func.isRequired,
  };
export default Login;
