import React, { useEffect, useState } from 'react'
import { CurrentOrderGraph } from '../components'
import axios from 'axios'
import { useGraphDetails } from './hooks/graph'
import Cookies from 'js-cookie'; // Import js-cookie


const baseURL = process.env.REACT_APP_BASE
const Graph = () => {
  // const token = localStorage.getItem('token')
  const token = Cookies.get('accessToken');

  const data=useGraphDetails()

  const [graphData, setGraphData] = useState([])

  // const fetchGraph = async () => {
  //   try {
  //     const response = await axios.get(`${baseURL}/api/production-graph/`,{
  //       headers: {
  //         Authorization: `Bearer ${token}`
  //     }
  //     });
  //     console.log("🚀 ~ fetchGraph ~ response:", response)
  //     setGraphData(response?.data);
  //   } catch (error) {
  //     console.error('Error fetching graph:', error);
  //   }
  // };

  // useEffect(() => {
  //   fetchGraph();
  // }, []);

  return (
    <div style={{padding:'20px'}}>
        <CurrentOrderGraph />
    </div>
  )
}

export default Graph