import { useMutation, useQuery } from "react-query";
import axiosInstance from "../../service/axiosInstance";
import ENDPOINTS from "../../service/endpoint";

// login api call (POST WITH DATA)---
const fetchLogin = async (params) => {
  const { data } = await axiosInstance({
    method: "post",
    url: ENDPOINTS.LOGIN,
    data: params,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useLogin = () => {
  return useMutation({
    queryKey: ["useLogin"],
    mutationFn: (params) => fetchLogin(params),
  });
};

const fetchEmployeeList = async (params) => {
  const { data } = await axiosInstance({
    method: "get",
    url: ENDPOINTS.EMPLOYEE_LIST,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useEmployeeList = () => {
  return useQuery({
    queryKey: ["useEmployeeList"],
    queryFn: (params) => fetchEmployeeList(params),
  });
};

const fetchEmployeeListById = async (params) => {
  console.log("🚀 ~ fetchEmployeeListById ~ params:", params);
  const { data } = await axiosInstance({
    method: "get",
    url: `${ENDPOINTS.GET_USER_BY_ID}/${params?.params}`,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useEmployeeListById = () => {
  return useMutation({
    queryKey: ["useEmployeeListById"],
    mutationFn: (params) => fetchEmployeeListById(params),
  });
};

export { useLogin, useEmployeeList, useEmployeeListById };
