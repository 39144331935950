import { useQuery, useMutation } from "react-query";
import axiosInstance from "../../service/axiosInstance";
import ENDPOINTS from "../../service/endpoint";

const fetchCustomerList = async (params) => {
  const { data } = await axiosInstance({
    method: "get",
    url: ENDPOINTS.CUSTOMER_LIST,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};
const useCustomerList = () => {
  return useQuery({
    queryKey: ["useCustomerList"],
    queryFn: (params) => fetchCustomerList(params),
  });
};

const addCustomer = async (params) => {
  const { data } = await axiosInstance({
    method: "post",
    url: ENDPOINTS.CUSTOMER_LIST,
    headers: { "Content-Type": "application/json" },
    data: params?.payload,
  });
  return data;
};
const useCustomerAdd = () => {
  return useMutation({
    queryKey: ["useCustomerAdd"],
    mutationFn: (params) => addCustomer(params),
  });
};

const editCustomer = async (params) => {
  const { data } = await axiosInstance({
    method: "put",
    url: `${ENDPOINTS.EDIT_CUSTOMER}${params?.id?.id}`,
    headers: { "Content-Type": "application/json" },
    data: params,
  });
  return data;
};
const useCustomerEdit = () => {
  return useMutation({
    queryKey: ["useCustomerEdit"],
    mutationFn: (params) => editCustomer(params),
  });
};

const deleteCustomer = async (params) => {
  console.log(params);
  const { data } = await axiosInstance({
    method: "delete",
    url: `${ENDPOINTS.CUSTOMER_LIST}${params?.userId}/${params?.id}`,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};
const useCustomerDelete = () => {
  return useMutation({
    queryKey: ["useCustomerDelete"],
    mutationFn: (params) => deleteCustomer(params),
  });
};

const fetchCustomer = async (params) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `${ENDPOINTS.EDIT_CUSTOMER}${params?.id?.id}`,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};
const useCustomerFetch = () => {
  return useMutation({
    queryKey: ["useCustomerFetch"],
    mutationFn: (params) => fetchCustomer(params),
  });
};

export {
  useCustomerList,
  useCustomerAdd,
  useCustomerEdit,
  useCustomerDelete,
  useCustomerFetch,
};
