import React, { useEffect, useState } from 'react'
import { Accordian } from '../../components';
import { useSkuDetails, useSKUStatus } from '../hooks/wareHouse';
import { ClipLoader } from 'react-spinners';
import {capitalizeFirstLetter} from '../../utils/index'


const SkuDetails = () => {
    const [skuDetails, setSkuDetails] = useState([])
    const [openAccordionIndex, setOpenAccordionIndex] = useState(-1);
    const { data:sku, isLoading}=useSkuDetails()
const {mutateAsync}=useSKUStatus()
    useEffect(() => {
      setSkuDetails(sku?.data?.data)
    }, [sku])
    

    const toggleAccordion = (index) => {
      setOpenAccordionIndex(openAccordionIndex === index ? -1 : index);
    };
   
    const handleStatusChange =(newStatus, id)=>{
      const confirmUpdate = window.confirm(`Are you sure you want to change the SKU Status to ${capitalizeFirstLetter(newStatus)}?`);
    if (confirmUpdate) {
      mutateAsync({
        id: id,
        status: {
          status: newStatus
        }
      });
    }
  };
      
  return (
    <div className="mx-auto" > 
      <div className='flex justify-around p-4 mb-2 bg-gray-200 text-center pr-10 '> 
       <p className='w-1/5'>Date</p>
      <p className='w-1/5'>LI Name</p>
        <p className='w-1/5'>Assembly Line</p>
        <p className='w-1/5'>Related Product</p>
        {/* <p className='w-1/5'>Quantity</p> */}
        <p className='w-1/5'>Status</p>
      </div>
    { isLoading ?(
      <div className='' style={{display:'flex',justifyContent:'center'}}>
      <ClipLoader size={100}/>
</div>
    ):(
    
      skuDetails?.map((item, index)=>  {
        const date =new Date(item?.createdAt)
        return(
      <Accordian
        key={item._id}
        titleItems={[date?.toDateString(), item?.nameLI, item?.selectLine, item?.productName, (item?.status=== "received" ? "Delivered" : item?.status=== "request" ? "Request" : "")]}
        isOpen={openAccordionIndex === index}
        onClick={() => toggleAccordion(index)}
      >
{/* <div className="flex justify-center items-center gap-5 mb-4">
              <label htmlFor={`statusSelect-${item._id}`} className="block font-medium mb-1">
                Select Status:
              </label>
              <select
                id={`statusSelect-${item._id}`}
                onChange={(e) => handleStatusChange(e.target.value, item._id)}
                className="p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 "
                style={{width:'200px'}}
              >
               <option value="">Change Status</option>
                <option value="request">Request</option>
                <option value="delivered">Delivered</option>
              </select>
            </div> */}

            <table className="w-full text-left mb-10 ">
          <thead>
            <tr className='bg-slate-300'>
              <th className="border px-4 py-2 w-1/2">Related SKU Name ({item?.productName})</th>
              <th className="border px-4 py-2 w-1/2">Quantity ({item?.productQuantity})</th>
            </tr>
          </thead>
          <tbody>
            {item?.collectiveSKUDetails?.map(material => (
              <tr key={material._id}>
                <td className="border px-4 py-2 w-1/2">{material?.name}</td>
                <td className="border px-4 py-2 w-1/2">  {material && item
    ? (parseInt(material.quantity) * parseInt(item.productQuantity))
    : 'N/A'}
    </td>
              </tr>
            ))}
          </tbody>
        </table>


            {item?.rawMaterial?.length > 0 && (
              
            
        <table className="w-full text-left">
          <thead>
            <tr className='bg-slate-300'>
              <th className="border px-4 py-2 w-1/2">Other SKU Name</th>
              <th className="border px-4 py-2 w-1/2">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {item?.rawMaterial?.map(material => (
              <tr key={material._id}>
                <td className="border px-4 py-2">{material?.materialName}</td>
                <td className="border px-4 py-2">{material?.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
        )}
      </Accordian>
        )
})
)
}
  </div> 
   )
}

export default SkuDetails
